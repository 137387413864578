import {Input, Table} from "semantic-ui-react";
import {ActivationRecord} from "../Models/ActivationRecord";
import {RevokeDataLoggerModal} from "./RevokeDataLoggerModal";

interface UnlockHistoryProps {
    modelsPurchased: Array<ActivationRecord>
    onRevoke: () => void
}

export function UnlockHistory(props: UnlockHistoryProps) {
    return (
        <Table >
            <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>Series</Table.Cell>
                <Table.Cell>Activated By</Table.Cell>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>Reason for revocation</Table.Cell>
            </Table.Row>
            {props.modelsPurchased.map((ar) => <Table.Row>
                <Table.Cell>{ar.revoked ? null : <RevokeDataLoggerModal onRevoke={props.onRevoke} instance={ar} />}</Table.Cell>
                <Table.Cell>{ar.series}</Table.Cell>
                {/*Why is this column 5 wide, and the email inside an input field? It's large enough to show a reasonable amount of the email, but leaves space for the revocation reason.
                    Example email that I had to control the size for: ametekstcfederated_alexandria.davis@ametek.com
                    It would take over a lot of space, and the reason would only get a few words before a line break.
                */}
                <Table.Cell width={5}><Input fluid onChange={() => {}} value={ar.purchasedByEmail} /></Table.Cell>
                <Table.Cell>{ar.revoked ? "Revoked" : "Active"}</Table.Cell>
                <Table.Cell>{ar.revokeReason}</Table.Cell>
            </Table.Row>)
            }
        </Table>
    )
}