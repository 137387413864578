import {Card, Header} from "semantic-ui-react";
import {PieChart} from "react-minimal-pie-chart";
import {LabelRenderProps} from "react-minimal-pie-chart/types/Label";
import {BaseDataEntry} from "react-minimal-pie-chart/types/commonTypes";

export interface OrganizationsCardProps {
    orgs: Array<{ key: string, value?: number }>
}

export function OrganizationsCard(props: OrganizationsCardProps) {
    const pieLabel = (props: LabelRenderProps<BaseDataEntry>) => {
        if (props.dataEntry.value > 0)
            return props.dataEntry.value
    }

    let pieData = props.orgs.map((org, idx) => {
        let prev = 0
        if (idx > 0)
            prev = props.orgs[idx - 1].value ?? 0
        return {
            title: org.key == "Total" ? "Orgs older than a year" : `Orgs created in the past ${org.key.toLowerCase()}`,
            color: ["#AA5555", "#55AA55", "#5555AA"][idx % 3],
            key: org.key,
            value: (org.value ?? 0) - prev
        }
    })

    return (
        <Card fluid>
            <Card.Header>
                <Header textAlign={"center"}>
                    New Organizations
                </Header>
            </Card.Header>
            <Card.Content>
                <div>
                    {/* The Pie Chart will take over its container. Wrapping it in a Div prevents it from pushing the bullet points out.*/}
                    <PieChart
                        data={pieData}
                        startAngle={360 - 90}
                        lineWidth={50}
                        lengthAngle={-360}
                        label={pieLabel}
                        labelPosition={75}
                        animate={true}
                        paddingAngle={2.5}
                    />
                </div>

            </Card.Content>

        </Card>
    )
}