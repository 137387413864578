import {useEffect, useState} from 'react';
import {MessageFeed} from '@ametektci/ametek.stcappscommon';
import {Container, Grid, Modal, Transition} from "semantic-ui-react";
import AppHeader from "./global/AppHeader";
import Sidebar from "./global/Sidebar";
import ADAuthStore from '@ametektci/ametek.adauth';
import {StcApplicationsAPIContextWrapper} from "./contexts/StcApplicationsAPI";
import {OrganizationsContextWrapper} from "./contexts/OrganizationsContext";
import {Outlet} from "react-router-dom";

export default function App() {
    const [authenticated, setAuthenticated] = useState(false)
    const [authorizedForCustomerService, setAuthorizedForCustomerService] = useState(false)
    const [visible, setVisible] = useState(true)
    
    useEffect(() => {
        ADAuthStore.loginWithRedirect().then(newAuthenticated =>
            ADAuthStore.getUserCognitoGroups().then(groups => {
                setAuthenticated(newAuthenticated!)
                setAuthorizedForCustomerService(groups.includes("authorized_customer_service"))
            }))
    }, [])

    const onPanelHidden = () => {
        setVisible(true)
    }


    if (!authenticated)
        return null;

    if (!authorizedForCustomerService)
        return (
            <Modal header='Not Authorized' open={true}
                   content='Please contact the STC Software Team to grant authorization for STC apps customer service access'
                   actions={[{key: 'logout', content: 'Logout', color: 'red', onClick: ADAuthStore.logout}]}/>
        )

    return (
        <div className="App">
            <StcApplicationsAPIContextWrapper>
                <OrganizationsContextWrapper>
                <AppHeader/>

                <Container style={{paddingTop: '6em'}}>
                    <Grid stackable>
                        <Grid.Column width={4}>
                            <Sidebar/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Transition visible={visible} duration={300} animation='fade right'
                                        onHide={onPanelHidden}>
                                <Container content={<Outlet />}/>
                            </Transition>
                        </Grid.Column>
                    </Grid>
                </Container>
                <MessageFeed/>
                </OrganizationsContextWrapper>
            </StcApplicationsAPIContextWrapper>
        </div>
    );

}
