import {DateUtils} from '@ametektci/ametek.stcappscommon';
import {Accordion, Button, Grid, Header, Icon, Segment} from 'semantic-ui-react'
import EnableDataLog from "./EnableDataLog";
import {Gauge} from "@ametektci/ametek.stcappscommon";
import {useContext, useState} from "react";
import {StcApplicationsAPIContext} from "../contexts/StcApplicationsAPI";
import {UnlockHistory} from "./UnlockHistory";
import {ActivationRecord} from "../Models/ActivationRecord";

interface DeviceDetailsProps {
    device: Gauge
    onDataLogEnabled: (success: boolean) => void
    activations: Array<ActivationRecord>
    onRevoke: () => void
}
export default function DeviceDetails(props: DeviceDetailsProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [code, setCode] = useState('Loading')
    const [showUnlockCode, setShowUnlockCode] = useState(false)
    const [showActivations, setShowActivations] = useState(false)
    const loadCode = () => {
        api.generateUnlockCode(device.serialNumber, device.model.shortName).then((code) => {
            setCode(code.passwordReset)
        })
        setShowUnlockCode(!showUnlockCode)
    }
    let device = props.device
        return (
            <Segment>
                <Header size='huge' content={`${device.model.shortName} ${device.serialNumber}`} color='grey'/>
                
                <Grid columns='equal'>
                    <Grid.Column>
                        <Header content={device.serialNumber} subheader='Serial Number' size='small' block={false}/>
                        <Header content={device.dataLogEnabled ?  "Data Logger Enabled" :device.dataLogPurchased ? "Waiting to Enable" : "Not Enabled"} subheader='Data Logger'
                                color={(device.dataLogEnabled || device.dataLogPurchased) ? "green" : "red"} size='small' block={false}/>
                        <Header content={showUnlockCode? code : "Hidden" } size={"small"} subheader={"Password Reset Code"} />
                    </Grid.Column>
                    <Grid.Column>
                        <Header content={device.name} subheader={'Name'} size={"small"} block={false} />
                        <Header content={device.model.name} subheader='Model' size='small' block={false}/>
                        <Header content={DateUtils.FormatDate(device.lastSeen)} subheader='Last Connected At' size='small' block={false}/>
                    </Grid.Column>
                </Grid>
                <br />
                <Accordion>
                    <Accordion.Title onClick={() => setShowActivations(!showActivations)}>
                        <Header><Icon name={showActivations? "triangle down":"triangle right"} />DataLoggerXpActivations</Header>
                    </Accordion.Title>
                    <Accordion.Content active={showActivations}>
                        <UnlockHistory modelsPurchased={props.activations} onRevoke={props.onRevoke} />
                    </Accordion.Content>
                </Accordion>
                <Segment>
                    <Header dividing content='Controls' color='grey' size='small'/>
                    <Grid columns={3}>
                        <Grid.Column><EnableDataLog device={device} onDataLogEnabled={props.onDataLogEnabled} /></Grid.Column>
                        <Grid.Column><Button onClick={() => loadCode()} active={showUnlockCode} >{showUnlockCode?"Hide Reset Code" : "Show Reset Code"}</Button></Grid.Column>
                    </Grid>
                </Segment>

            </Segment>
        )
}
