import {useContext, useState} from 'react';
import {Button, Header, Modal, SemanticSIZES} from 'semantic-ui-react';
import {User} from "../../Models/User";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface RemoveUserProps
{
    user: User
    size?: SemanticSIZES
}
export default function RemoveUser(props: RemoveUserProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const open = () => {
        setVisible(true)
    };
    const close = () => {
        if (!loading)
        {
            setVisible(false)
        }
    }

    const removeUser = () => {
        setLoading(true)

        api.removeUser(props.user.email).then((success) => {
            setLoading(false)
            setVisible(!success)
        });
    }

    return (
        <Modal size='tiny' open={visible}
               trigger={<Button color='red' size={props.size || 'tiny'} content='Remove' onClick={open}/>} >
            <Modal.Header>Confirm Remove User</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header content={props.user.name} subheader={props.user.isAdmin ? 'Admin' : 'Standard User'}/>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Cancel' />
                <Button negative disabled={loading}
                        loading={loading} onClick={removeUser} content='Remove User' />
            </Modal.Actions>
        </Modal>
    )
}