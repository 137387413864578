import {Button, Form, Message, Modal, Table} from "semantic-ui-react";
import {ActivationRecord} from "../Models/ActivationRecord";
import {useContext, useState} from "react";
import {StcApplicationsAPIContext} from "../contexts/StcApplicationsAPI";

interface RevokeDataLoggerModalProps {
    instance: ActivationRecord
    onRevoke: () => void
}

function ShowActivationRecord(props: {
    instance: ActivationRecord
}) {
    return <Table>
        <Table.Row>
            <Table.Cell>
                Purchased On
            </Table.Cell>
            <Table.Cell>
                Bought By
            </Table.Cell>
            <Table.Cell>
                Invoice/Purchase Info
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>
                {props.instance.timestamp}
            </Table.Cell>
            <Table.Cell>
                {props.instance.purchasedByEmail}
            </Table.Cell>
            <Table.Cell>
                {props.instance.invoiceInfo}
            </Table.Cell>
        </Table.Row>
    </Table>;
}

export function RevokeDataLoggerModal(props: RevokeDataLoggerModalProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [reason, setReason] = useState("")
    const [open, setOpen] = useState(false)
    const onRevoke = async () => {
        await api.revokeActivationRecord(props.instance.deviceActivationRecordId, reason)
        props.onRevoke()
    }
    return (
        <Modal
            trigger={<Button content={'Revoke'}/>}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Modal.Header>
                Revoke DataLoggerXP purchase for {props.instance.series} {props.instance.serialNumber}
            </Modal.Header>
            <Modal.Content>
                <Message>
                    Invalidating this record will:
                    <ul>
                        <li>prevent it from being used to activate DataLoggerXP</li>
                        <li>Allow customers to purchase DataLoggerXP for this gauge in the future</li>
                    </ul>
                    This <strong>will not</strong> disable DataLoggerXP on gauges that already have it enabled.
                </Message>
                <ShowActivationRecord instance={props.instance}/>
                <Form>
                    <Form.Input label={"Reason for revoking"} value={reason}
                                onChange={(e, {value}) => setReason(value)}/>
                    <Form.Button content={"Revoke"} disabled={reason.length < 1} onClick={onRevoke}/>
                </Form>
            </Modal.Content>
        </Modal>
    )
}