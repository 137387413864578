const _envSettings = {
    'local' : {
        CcwApiUrl : 'http://localhost:5000',
    },
    'dev' : {
        CcwApiUrl : 'https://api-datalogging-dev.stcapplications.com',
    },
    'staging' : {
        CcwApiUrl : 'https://api-datalogging-staging.stcapplications.com',
    },
    'production' : {
        CcwApiUrl : 'https://api.crystalcontrolweb.com',
    }
};

export const GetEnvironmentSetting = function(key : keyof typeof _envSettings["production"]) {
    // determine the env
    let env = GetEnvironment();
    // get the appropriate value for the env
    let value = _envSettings[env][key];
    // if we couldn't find it, alert ray gun, something is missing
    if ( ! value ) {
        console.error('unknown environment variable', key);
    }

    return value;
};
export const GetEnvironment = function() : "dev" | "local" | "staging" | "production" {
    // return 'dev'
    if (window.location.hostname.includes('localhost'))
        return 'local';
    else if (window.location.hostname.includes('dev'))
        return 'dev';
    else if (window.location.hostname.includes('staging'))
        return 'staging';

    return 'production';
};

export const ExportEnvironmentSettings = function() {
    let env = GetEnvironment();
    return _envSettings[env];
};
