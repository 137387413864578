import {useContext, useState} from 'react'
import {Button, Card, Form, Grid, Header, Loader, Segment} from 'semantic-ui-react'
import DeviceDetails from "./DeviceDetails";
import {Gauge} from "@ametektci/ametek.stcappscommon";
import {StcApplicationsAPIContext} from "../contexts/StcApplicationsAPI";
import {DeviceInfoCard} from "./DeviceInfoCard";
import {DisconnectedGaugeTools} from "./GenrateUnlockCode";
import {ActivationRecord} from "../Models/ActivationRecord";

export default function DeviceLookup() {
    const api = useContext(StcApplicationsAPIContext)
    const [serialNumber, setSerialNumber] = useState('')
    const [searchedSerial, setSearchedSerial] = useState('')
    const [loading, setLoading] = useState(false)
    const [noResults, setNoResults] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState<Gauge>()
    const [deviceSearchResults, setDeviceSearchResults] = useState<Array<Gauge>>([])
    const [modelsPurchased, setModelsPurchased] = useState<Array<ActivationRecord>>([])
    const getActivations = () => {
        api.getDlActivationRecords(serialNumber).then(setModelsPurchased)
    }
    const searchDevices = () => {
        setLoading(true)

        api.searchDevices(serialNumber).then(SearchResults =>
        {
            setSearchedSerial(serialNumber)
            setDeviceSearchResults(SearchResults)
            setLoading(false)
            setNoResults(SearchResults.length === 0)
            if (SearchResults.length === 1)
                setSelectedDevice(SearchResults[0])
            else 
                setSelectedDevice(undefined)
        })
        getActivations()
    };
    

    const onDataLogEnabled = (success : boolean) => {
        if (success) {
            setSelectedDevice({...selectedDevice!, dataLogEnabled: true})
            getActivations()
        }
    }

        return (
            <Segment >
                <Header content='Device Lookup' size='huge'/>

                <Segment >
                    <Header dividing content='Search' color='blue' />

                    <Form onSubmit={searchDevices}>
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={13}>
                                <Form.Input label='Serial Number' onChange={(e, {value}) => setSerialNumber(value)}
                                            placeholder='Serial Number' />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Button content='Search' color='blue' fluid
                                        disabled={loading || serialNumber.length < 6} />
                            </Grid.Column>
                        </Grid>
                    </Form>

                </Segment>
                {loading ? <Loader active inline='centered'/> :
                    noResults ? <div>
                        <Header size='medium' textAlign='center' disabled content='No Devices Found'/>
                        <DisconnectedGaugeTools sn={searchedSerial}/>
                    </div> : 
                        selectedDevice != null? <DeviceDetails onRevoke={getActivations} activations={modelsPurchased} device={selectedDevice} onDataLogEnabled={onDataLogEnabled} /> : null
                }
                
                <Card.Group itemsPerRow={3}>
                    {deviceSearchResults.map(device => (
                        <DeviceInfoCard device={device} onSelected={() => setSelectedDevice(device)} key={device.gaugeId} selected={device.gaugeId == selectedDevice?.gaugeId} />
                    ))}
                </Card.Group>
                &nbsp;
            </Segment>
        )
    
}
