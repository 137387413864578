import {Confirm, Form, Header} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {StcApplicationsAPIContext} from "../contexts/StcApplicationsAPI";
import {ActivationRecord} from "../Models/ActivationRecord";
import {UnlockHistory} from "./UnlockHistory";
interface GenerateUnlockCodeProps {
    sn?: string
}
export function DisconnectedGaugeTools(props: GenerateUnlockCodeProps) {
    const api = useContext(StcApplicationsAPIContext)
    const supportedModels = ["XP3I"]
    type supportedModel = typeof supportedModels[number]
    const [model, setModel] = useState<supportedModel>("XP3I")
    const serialNumber = props.sn ?? ""
    const [unlockCode, setUnlockCode] = useState("Hidden")
    const [detailsLoaded, setDetailsLoaded] = useState<boolean | "loading">(false)
    const [modelsPurchased, setModelsPurchased] = useState<Array<ActivationRecord>>([])
    const bought = modelsPurchased.some(m => m.series.toUpperCase() == model.toUpperCase());
    const [confirmEnableDL, setConfirmEnableDL] = useState(false)
    const loadCode = () => {
        api.generateUnlockCode(serialNumber, model).then((code) => {
            setUnlockCode(code.passwordReset)
        })
    }
    const loadDetails = () => {
        setDetailsLoaded("loading")
        api.getDlActivationRecords(serialNumber).then(purchases => {
            setModelsPurchased(purchases)
        })
            .finally(() => setDetailsLoaded(true))
    }
    
    useEffect(() => {
        setUnlockCode("Hidden")
    },[model, serialNumber])
    useEffect(() => {
        setDetailsLoaded(false)
        setModelsPurchased([])
        loadDetails()
    }, [serialNumber])
    
    return <Form>
        <Header textAlign={"center"}>
            <Header.Content>
                Manage Unconnected Gauge {serialNumber}
            </Header.Content>
        </Header>
        
        <Form.Group widths={"equal"}>
            <Form.Dropdown
                label={"Series"} 
                value={model} 
                options={supportedModels.map(m => ({text: m, value: m}))}
                onChange={(_e, {value}) => setModel(value as supportedModel)}
                selection
            />
            
        </Form.Group>
        <Form.Group widths={"equal"}>
            <Form.Input label={"Unlock Code"} value={unlockCode} onChange={() => {}} />
            <Form.Button fluid onClick={() =>loadCode()} label={<span>&nbsp;</span>}>
                Generate
            </Form.Button>
        </Form.Group>
        <Form.Group widths={"equal"}>
            <Form.Button
                label={<span>&nbsp;</span>}
                color={"green"} onClick={() => setConfirmEnableDL(true)}
                disabled={detailsLoaded != true ||bought}
                loading={detailsLoaded=="loading"}
                content={bought? "Datalogger Already Purchased" : "Pre-Enable DataLoggerXP"}
                fluid
            />
        </Form.Group>
        <UnlockHistory modelsPurchased={modelsPurchased} onRevoke={loadDetails} />
        <Confirm onClose={() => setConfirmEnableDL(false)} 
                 onCancel={() => setConfirmEnableDL(false)} 
                 open={confirmEnableDL}
                 header={`Enable DataloggerXP for ${model} ${serialNumber}?`}
                 onConfirm={() => {
            setDetailsLoaded("loading")
            api.enableDataLoggerXP(serialNumber, model).then(() => loadDetails())
            setConfirmEnableDL(false)
        }}
                 content={"This feature will be enabled when the gauge is connected to Crystal Control Web"}
        />
    </Form>
}