import {useContext, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface CancelSubscriptionModalProps {
    organizationId: number
    subscriptionId: number
    //we probably should not be passing back the subscription ID
    //since we got it from the props
    //but fixing it is out of scope of the current changes.
    onCancel: (subscriptionId: number,success: boolean) => void
}
export default function CancelSubscriptionModal (props: CancelSubscriptionModalProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => setVisible(true)

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const cancelSubscription = () => {
        setLoading(true)
        api.cancelSubscription(props.organizationId, props.subscriptionId)
            .then(success => {
                setLoading(false)
                setVisible(!success)
                props.onCancel(props.subscriptionId, success)
            })
    }
    
    return (
        <Modal open={visible} centered={false}
               trigger={ <Button icon='delete' color='red' content='Cancel Subscription' onClick={open} /> }>
            <Modal.Header>Cancel Subscription</Modal.Header>
            <Modal.Content>
                   Are you sure you want to cancel this subscription?
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Close' />
                <Button onClick={cancelSubscription} disabled={loading} color='red' content='Cancel Subscription' />
            </Modal.Actions>
        </Modal>
    )
}