import {Organization} from "../../Models/Organization";
import {Accordion, Card, Header, Icon, Segment} from "semantic-ui-react";
import {DeviceInfoCard} from "../../crystalcontrolweb/DeviceInfoCard";
import DeviceDetails from "../../crystalcontrolweb/DeviceDetails";
import {useContext, useState} from "react";
import {Gauge} from "@ametektci/ametek.stcappscommon";
import {ActivationRecord} from "../../Models/ActivationRecord";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";

export function OrganizationGauges(props: OrganizationGaugesProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [selectedDevice, setSelectedDevice] = useState<Gauge>()
    const [open, setOpen] = useState((props.org.Gauges?.length ?? 0) < 10)
    const [modelsPurchased, setModelsPurchased] = useState<Array<ActivationRecord>>([])

    const getActivations = (serialNumber: string) => {
        api.getDlActivationRecords(serialNumber).then(setModelsPurchased)
    }
    const onSelectedDeviceChange = (device: Gauge | undefined) => {
        setModelsPurchased([])
        if (device != null)
            getActivations(device.serialNumber)
        setSelectedDevice(device)
    }
    const onDataLogEnabled = (success: boolean) => {
        if (success) {
            setSelectedDevice({...selectedDevice!, dataLogEnabled: true})
        }
    }
    return (
        <Accordion as={Segment}>
            <Accordion.Title onClick={() => setOpen(!open)}>
            <Header dividing content='Gauges' color='grey' size='small' icon={<Icon name={open? "triangle down" : "triangle right"} />} />
            </Accordion.Title>
            <Accordion.Content active={open}>
            {selectedDevice != null ?
                <DeviceDetails onRevoke={() => getActivations(selectedDevice.serialNumber)} activations={modelsPurchased} device={selectedDevice} onDataLogEnabled={onDataLogEnabled}/> : null}
            {(props.org.Gauges?.length ?? 0) < 1 ?
                <Header size='medium' textAlign='center' disabled content='No Connected Gauges'/>
                :
                <Card.Group itemsPerRow={3}>
                    {
                        props.org.Gauges?.map(device => (
                            <DeviceInfoCard device={device} onSelected={() => onSelectedDeviceChange(device)}
                                            key={device.gaugeId}
                                            selected={device.gaugeId == selectedDevice?.gaugeId}/>
                        ))}
                </Card.Group>
            }
            </Accordion.Content>
        </Accordion>
    )
}

export interface OrganizationGaugesProps {
    org: Organization
}