import {Image, Menu, Icon, Header} from 'semantic-ui-react';
import hpc50 from "../images/HPC50Icon.svg";
import {useLocation, useNavigate} from "react-router"
export default function Sidebar(){
    const {pathname } = useLocation()
    const path = pathname.split("/").filter(p => p.length > 0)[0]
    const navigate = useNavigate();
        return (
            <span>
                <Menu fluid vertical icon={false}>
                    <Menu.Item header>
                        <Header size={"small"}>
                            System Overview
                        </Header>
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/dashboard")} active={path == "dashboard"}>
                        Dashboard
                    </Menu.Item>
                </Menu>
                <Menu fluid vertical icon={false}>
                    <Menu.Item header>
                        <Header content='Search' size='small'/>
                    </Menu.Item>

                    <Menu.Item onClick={() => navigate('/user')}
                               active={path === 'user'}>
                        <Icon name='user' size='small' color='blue'/>
                        Lookup User
                    </Menu.Item>

                    <Menu.Item onClick={() => navigate('/organization')}
                               active={path === 'organization'}>
                        <Icon name='users' size='small' color='blue'/>
                        Lookup Organization
                    </Menu.Item>
                </Menu>

                <Menu fluid vertical icon={false}>
                    <Menu.Item header>
                        <Header content='Price Management' size='small'/>
                    </Menu.Item>
                    {/*
                    <Menu.Item onClick={() => navigate('/addSubscription')}
                               active={path === 'addSubscription'}>
                        <Icon name='clipboard' size='small' color='blue'/>
                        Add P.O. Subscription
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate('/managePlans')}
                               active={path === 'managePlans'}>
                        <Icon name='dollar sign' size='small' color='blue'/>
                        Subscription Options
                    </Menu.Item>
                    */}
                    <Menu.Item onClick={() => navigate('/managePurchases')}
                               active={path === 'managePurchases'}>
                        <Icon name='dollar sign' size='small' color='blue'/>
                        One Time Purchases
                    </Menu.Item>
                </Menu>

                <Menu fluid vertical icon={false}>
                    <Menu.Item header>
                        <Header content='Organization Management' size='small'/>
                    </Menu.Item>

                    <Menu.Item onClick={() => navigate('/mergeOrganizations')}
                               active={path === 'mergeOrganizations'}>
                        <Icon name='code branch' size='small' color='blue'/>
                        Merge Organizations
                    </Menu.Item>
                </Menu>

                <Menu fluid vertical icon={false}>
                    <Menu.Item header>
                        <Header content='CrystalControlWeb' size='small'/>
                    </Menu.Item>

                    <Menu.Item onClick={() => navigate('/ccwDevices')}
                               active={path === 'ccwDevices'}>
                        <Image src={hpc50} style={{height: '27px', float: 'right', top: '-7px'}}/>
                        Devices
                    </Menu.Item>
                </Menu>
            </span>
        )
}
