import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import App from "./App";
import {Dashboard} from "./stcapplications/Overview/Dashboard";
import UserLookup from "./stcapplications/users/UserLookup";
import OrganizationSearch from "./stcapplications/organizations/OrganizationSearch";
import PurchaseOrder from "./stcapplications/subscriptions/PurchaseOrder";
//import ManagePlans from "./stcapplications/Plans/ManagePlans";
import MergeOrganizations from "./stcapplications/organizations/MergeOrganizations";
import DeviceLookup from "./crystalcontrolweb/DeviceLookup";
import {ManageOneTimePurchasable} from "./stcapplications/Plans/ManageOneTimePurchasable";
export function AppWithRoutes() {
    const router = createBrowserRouter([
        {
            path: "/*",
            element: <App />,
            children: [
                //System Overview
                {path: 'dashboard', element: <Dashboard />},
                //Search section
                {path: 'user', element: <UserLookup/>},
                {path: 'organization', element: <OrganizationSearch/>},
                //App Subscriptions Section
                {path: 'addSubscription', element: <PurchaseOrder/>},
                //{path: 'managePlans', element: <ManagePlans/>},
                //Org Management section
                {path: 'mergeOrganizations', element: <MergeOrganizations/>},
                //Crystal Control Web Section
                {path: 'ccwDevices', element: <DeviceLookup/>},
                {path: 'managePurchases', element: <ManageOneTimePurchasable />},
                {path: '*', element: <Navigate to={"/dashboard"}/>},
            ]
        }
    ])
    return (
        <RouterProvider router={router} />
    )
}