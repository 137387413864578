import {Card, Header} from "semantic-ui-react";

export interface UsersCardProps {
    users: { users: number, admins: number }
}

export function UsersCard(props: UsersCardProps) {
    return (
        <Card fluid>
            <Card.Header>
                <Header textAlign={"center"}>
                    Users
                </Header>
            </Card.Header>
            <Card.Content>
                <ul>
                    <li>
                        Total users: {props.users.users}
                    </li>
                    <li>
                        admins: {props.users.admins}
                    </li>
                </ul>

            </Card.Content>
        </Card>
    )
}