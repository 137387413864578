import {useContext, useEffect, useState} from 'react'
import {Button, Card, Form, Grid, Header, Icon, Loader, Segment} from 'semantic-ui-react'
import {DateUtils} from '@ametektci/ametek.stcappscommon';
import stcApplicationsStore from "../../stores/StcApplicationsStore";
import OrganizationDetails from "./OrganizationDetails";
import ConfirmMerge from "./ConfirmMerge";
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
export default function MergeOrganizations() {
    const api = useContext(StcApplicationsAPIContext)
    const [organizationSearchResults, setOrganizationSearchResults] = useState<Array<Organization>>([])
    const [loading, setLoading] = useState(false)
    const [organizationName, setOrganizationName] = useState('')
    const [mergeFromOrganization, setMergeFromorganization] = useState<Organization | null>(null)
    const [mergeToOrganization, setMergeToOrganization] = useState<Organization | null>(null)
    const [noResults, setNoResults] = useState(false)
    const reset = () => {
        setOrganizationSearchResults([])
        setOrganizationName('')
        setMergeFromorganization(null)
        setMergeToOrganization(null)
    }
    useEffect(() => {
        stcApplicationsStore.on('organizationsMergedEvent', reset)
        return () => {
            stcApplicationsStore.off('organizationsMergedEvent', reset)
        }
    })
    const searchOrganization = () => {
        if (organizationName.length >= 3) {
            setLoading(true)
            setNoResults(false)

            api.searchOrganizations(organizationName).then((newOrgSearchResults) =>{
                setOrganizationSearchResults(newOrgSearchResults)
                setLoading(false)
                setNoResults(newOrgSearchResults.length === 0)
            }
            )
        }
    };
   
    let results = null;

    if (loading)
        results = <Loader active inline='centered'/>;
    else if (noResults)
        results = <Header size='medium' textAlign='center' disabled content='No Organizations Found'/>;
    else {
        results = <Card.Group itemsPerRow={3}>
            {organizationSearchResults.map(org => (
                <Card key={org.organizationId}>
                    <Card.Content header={org.name} />
                    <Card.Content extra content={DateUtils.FormatDate(org.createdAt)} />
                    <Card.Content extra>
                        <OrganizationDetails organizationId={org.organizationId} />
                        <br/>
                        <Button content='Set As Merge From' fluid color='teal' size='tiny'
                                disabled={!!mergeToOrganization &&
                                mergeToOrganization.organizationId === org.organizationId}
                                onClick={() => setMergeFromorganization(org)}/>
                        <br/>
                        <Button content='Set As Merge To' fluid color='teal' size='tiny'
                                disabled={!!mergeFromOrganization &&
                                          mergeFromOrganization.organizationId === org.organizationId}
                                onClick={() => setMergeToOrganization(org)}/>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    }

    return (
        <Segment >
            <Header content='Merge Organizations' size='huge'/>

            <Grid celled='internally'>
                <Grid.Column width={8}>
                    <Segment placeholder>
                        <Header icon>
                            <Icon name='sitemap' color={!mergeFromOrganization ? 'grey' : 'blue'} />
                            {!mergeFromOrganization ? 'From Organization' : mergeFromOrganization.name}
                            <Header.Subheader>Merge From</Header.Subheader>
                        </Header>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Segment placeholder>
                        <Header icon>
                            <Icon name='sitemap' color={!mergeToOrganization ? 'grey' : 'blue'} />
                            {!mergeToOrganization ? 'To Organization' : mergeToOrganization.name}
                            <Header.Subheader>Merge To</Header.Subheader>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid>

            <ConfirmMerge mergeFromOrganization={mergeFromOrganization!}
                          mergeToOrganization={mergeToOrganization!}
                          disabled={!mergeFromOrganization || !mergeToOrganization}/>

            <Segment >
                <Header dividing content='Search' color='blue' />

                <Form onSubmit={searchOrganization}>
                    <Grid verticalAlign='middle'>
                        <Grid.Column width={13}>
                            <Form.Input label='Organization Name' onChange={(e, {value}) => setOrganizationName(value) } name='organizationName'
                                        placeholder='Organization Name' />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button content='Search' color='blue' fluid
                                    disabled={loading || organizationName.length < 3} />
                        </Grid.Column>
                    </Grid>
                </Form>

            </Segment>

            {results}

        </Segment>
    )
}
