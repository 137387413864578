import {useContext, useState} from 'react'
import {Header, Step, Segment, Container, Button, Divider, Grid, Icon} from 'semantic-ui-react'
import OrganizationSearch from "../organizations/OrganizationSearch";
import EnterPurchaseOrderDetails from "./EnterPurchaseOrderDetails";
import PurchaseOrderConfirmation from "./PurchaseOrderConfirmation";
import {Organization} from "../../Models/Organization";
import {PurchaseOrderSubscription} from "../../Models/PurchaseOrderSubscription";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";

export default function PurchaseOrder() {
    const api = useContext(StcApplicationsAPIContext)
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState('selectOrg')
    const [organization, setOrganization] = useState<Organization | null>(null) //this says its an organization, but it's more of a stub. Do not trust it to contain more than identifying information.  
    const [orderNumber,setOrderNumber] = useState("")
    const [subscriptions, setSubscriptions] = useState<Array<PurchaseOrderSubscription>>([])
    
    const reset = () => {
        setLoading(false)
        setStep('selectOrg')
        setOrganization(null)
        setOrderNumber("")
        setSubscriptions([])
    }

    const onOrgSelect = (organization: Organization) => {
        setStep('poDetails')
        setOrganization(organization)
    }

    const enterDetails = (orderNumber: string, subscriptions: Array<PurchaseOrderSubscription>) => {
        setOrderNumber(orderNumber)
        setSubscriptions(subscriptions)
        setStep('confirm')
    }

    const submit = () => {
        setLoading(true)

        api.addPurchaseOrder(organization!.organizationId, orderNumber, subscriptions)
            .then(() => {
                setLoading(false)
                setStep('complete')
            })
    }


        return (
            <Segment >
                <Header content='Enter Purchase Order Subscription' size='huge'/>

                <Step.Group fluid>
                    <Step completed={step !== 'selectOrg'} active={step === 'selectOrg'}
                          title='Organization' description='Select the purchasing organization' icon='building' />
                    <Step completed={step === 'confirm' || step === 'complete'}
                          active={step === 'poDetails'}
                          title='P.O. Details' description='Enter the P.O. Details' icon='file alternate outline' />
                    <Step completed={step === 'complete'} active={step === 'confirm'}
                          title='Confirmation' description='Submit the P.O.' icon='check circle' />
                </Step.Group>


                { step === 'selectOrg' ? <OrganizationSearch onSelected={onOrgSelect} /> : null }

                { step === 'poDetails' ? <EnterPurchaseOrderDetails onSubmit={enterDetails} orgInfo={organization!}  /> : null }

                { step === 'confirm' ?
                    <PurchaseOrderConfirmation organization={organization!} orderNumber={orderNumber}
                                               subscriptions={subscriptions} onSubmit={submit}
                                               loading={loading} />
                    : null
                }

                {step === 'complete' ?
                    <Segment>
                        <Header color='green' content='Purchase Order Submitted' dividing/>

                        <Grid>
                            <Grid.Column width={4}>
                                <Icon size='huge' name='check circle' color='green' />
                            </Grid.Column>
                            <Grid.Column width={12} verticalAlign='middle'>
                                <Container content='Purchase order has been submitted and the seats are now assigned to the organization' />
                            </Grid.Column>
                        </Grid>

                        <Divider hidden />
                        <Divider hidden />

                        <Button content='Add Another' color='blue' onClick={reset} basic />

                    </Segment>
                    : null
                }


            </Segment>
        )
}
