import {Card, Header} from "semantic-ui-react";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import UserDetails from "./UserDetails";
import {userResult} from "../../Models/API Responses/userRequest";

export interface UserCardProps {
    user: userResult
    showOrg: boolean
}

export function UserCard(props: UserCardProps) {
    let username = props.user.user?.username ?? props.user.awsUserInfo?.username ?? "unknown"
    let date = props.user.user?.createdAt ?? props.user.awsUserInfo?.creation
    return (
        <Card key={username}>
            <Card.Content header={props.user.user?.name ?? "Name not found"}/>
            
            <Card.Content extra>
                {props.showOrg? <Header content={props.user.organizationName} subheader='Organization' size='small' sub={true}/> : null}
                <Header content={props.user.user?.isAdmin? 'Admin' : 'Standard'} subheader='Access Level' size='small' sub={true} color={props.user.user?.isAdmin ? 'green' : undefined}/>
                <Header content={`${DateUtils.FormatDate(date)}`} subheader='Joined' size='small' sub={true}/>
            </Card.Content>
            <Card.Content extra>
                <UserDetails user={props.user} hideOpenOrg={!props.showOrg}/>
            </Card.Content>
        </Card>
    )
}