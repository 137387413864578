import {Grid, Header, Segment} from "semantic-ui-react";
import OrganizationDetails from "../organizations/OrganizationDetails";

interface CurrentOrgInformationProps {
    orgName: string,
    hideOpenOrg?: boolean
    orgId: number
}
export function CurrentOrganizationInformation(props: CurrentOrgInformationProps)
{
    return (
        <Segment>
            <Header dividing content='Parent Organization' color='grey' size='small'/>
            <Grid columns='equal'>
                <Grid.Column>
                    <Header content={props.orgName} subheader='Organization Name' size='small' block={false}/>
                </Grid.Column>
                <Grid.Column>
                    {!!props.hideOpenOrg ? null :
                        <OrganizationDetails organizationId={props.orgId} />
                    }
                </Grid.Column>
            </Grid>
        </Segment>
    )
}