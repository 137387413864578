import {Organization} from "../../Models/Organization";
import {Accordion, Card, Header, Icon, Segment} from "semantic-ui-react";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import ExtendTrialPeriod from "./ExtendTrialPeriod";
import {useState} from "react";

export function OrganizationTrial(props: OrganizationTrialprops)
{
    const [open, setOpen] = useState(props.org.trials.length < 10)
    return (
        <Accordion as={Segment}>
            <Accordion.Title onClick={() => setOpen(!open)}>
                <Header dividing content='Trial Periods' color='grey' size='small' icon={<Icon name={open? "triangle down" : "triangle right"} />} />
            </Accordion.Title>
            <Accordion.Content active={open}>
                <Card.Group itemsPerRow={5}>
                    {props.org.trials.map(trial => (
                        <Card key={trial.trialId}>
                            <Card.Content header={trial.application.name}/>
                            <Card.Content description={DateUtils.HasPast(trial.endsAt) ? 'Expired' : "Expires " + DateUtils.FormatDate(trial.endsAt) }/>
                            <Card.Content extra>
                                <ExtendTrialPeriod trial={trial} organization={props.org} />
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>

                {props.org.trials.length === 0 ?
                    <Header size='medium' textAlign='center' disabled content='No Activated Trial Periods'/>
                    : null }
            </Accordion.Content>
        </Accordion>
    )
}
interface OrganizationTrialprops {
    org: Organization
}