
export function FormatOrganizationType(type: string) {
    if (type === 'internal')
        return 'AMETEK Internal Org';
    if (type === 'distributor')
        return 'Distributor Org'
    return 'Customer Org'
}

export function OrganizationTypeColor(type : string) {
    if (type === 'internal')
        return 'red';
    if (type === 'distributor')
        return 'olive'
    return 'blue'
}
