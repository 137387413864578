import {Organization} from "../../Models/Organization";
import {Accordion, Card, Grid, Header, Icon, Radio, Segment} from "semantic-ui-react";
import {DateUtils, Utils} from "@ametektci/ametek.stcappscommon";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import {useState} from "react";

export function OrganizationSubscriptions(props: OrganizationSubscriptionProps) {

    const [onlyShowActiveSubscriptions, setOnlyShowActiveSubscriptions] = useState(true)
    const [open, setOpen] = useState((props.org.billingItems?.length ?? 0) < 10)
    const toggleShowActiveOnly = () => setOnlyShowActiveSubscriptions(!onlyShowActiveSubscriptions)
    return (<Accordion as={Segment}>
        <Accordion.Title onClick={() => setOpen(!open)}>
        <Grid>
            <Grid.Column width={13}>
                <Header dividing content='Subscriptions' color='grey' size='small' icon={<Icon name={open? "triangle down" : "triangle right"} />} />
            </Grid.Column>
            <Grid.Column width={3}>
                <Radio label='Show Active Only' checked={onlyShowActiveSubscriptions}
                       onChange={toggleShowActiveOnly} toggle/>
            </Grid.Column>
        </Grid>
        </Accordion.Title>
        <Accordion.Content active={open}>
        {props.org.billingItems?.filter(bill => bill.status === 'ACTIVE' || !onlyShowActiveSubscriptions).map(bill => (
            <Segment.Group key={bill.subscriptionId}>
                <Segment color={bill.status === 'CANCELED' ? 'red' : undefined}>
                    <Grid>
                        <Grid.Column width={3}>
                            <Header content={bill.product} subheader='Subscription' size='tiny'/>
                            <Header content={bill.status} color={bill.status === 'CANCELED' ? 'red' : 'green'} subheader='Status' size='tiny'/>
                            {bill.status === 'CANCELED' ? null :
                                <Header content={DateUtils.FormatDate(bill.nextBillingDate)} subheader='Next Billing Date' size='tiny'/>                                                    }
                            <Header content={`${bill.cardType} ${bill.cardLastFourDigits}`} subheader='Charge Card' size='tiny'/>
                            <Header content={Utils.FormatCurrency(bill.chargeAmount)} subheader='Total' size='tiny'/>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Card.Group itemsPerRow={4}>
                                {props.org.seats.filter(s => s.blueSnapSubscriptionId === bill.subscriptionId).map(ss => {
                                    //let assignedUser = org.users.find(u => u.userId === ss.userId);
                                    return (
                                        <Card key={ss.seatId}>
                                            <Card.Content header='Seat'/>
                                            <Card.Content description={!!ss.serialNumber? `Assigned to ${ss.serialNumber}` : 'Unassigned'}/>
                                            {bill.status !== 'CANCELED' ? null :
                                                <Card.Content description={`Seat expires on : ${DateUtils.FormatDate(ss.expiresOn)}`}/> }
                                        </Card>
                                    )
                                })}
                            </Card.Group>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment textAlign='right'>
                    {bill.status === 'CANCELED' ? null :
                        <CancelSubscriptionModal organizationId={props.org.organizationId}
                                                 subscriptionId={bill.subscriptionId} onCancel={props.onSubscriptionCancel}/>
                    }
                </Segment>

            </Segment.Group>
        ))}
        </Accordion.Content>
    </Accordion>)
}
export interface OrganizationSubscriptionProps {
    org: Organization
    onSubscriptionCancel: (subscriptionId : number, success : boolean) => void
}