import {Accordion, AccordionContent, Card, Header, Icon, Segment} from "semantic-ui-react";
import {UserCard} from "../users/UserCard";
import {Organization} from "../../Models/Organization";
import {useState} from "react";

export interface OrganizationUsersProps {
    org: Organization
}

export function OrganizationUsers(props: OrganizationUsersProps) {
    const [open, setOpen] = useState(props.org.users.length < 10)
    return (
        <Accordion as={Segment}>
            <Accordion.Title onClick={() => setOpen(!open)}>
                <Header dividing content='Users' color='grey' size='small' icon={<Icon name={open? "triangle down" : "triangle right"} />}/>
            </Accordion.Title>
            <AccordionContent active={open}>
                {open ?

                    <>
                        <Card.Group itemsPerRow={5}>
                            {props.org.users.map(user => (
                                <UserCard user={ {organizationName: props.org.name, user: user} } showOrg={false} key={user.username}/>
                            ))}
                        </Card.Group>
                        {props.org.users.length === 0 ?
                            <Header size='medium' textAlign='center' disabled content='No Existing Users'/>
                            : null}
                    </>
                    : null}
            </AccordionContent>
        </Accordion>
    )
}