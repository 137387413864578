import {useContext, useState} from 'react';
import {Button, Header, Icon, Modal, Segment} from 'semantic-ui-react';
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface ConfirmMergeProps {
    mergeToOrganization: Organization
    mergeFromOrganization: Organization
    disabled: boolean
}
export default function ConfirmMerge(props: ConfirmMergeProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    

    const open = () => setVisible(true);
    const close = () => {
        if ( ! loading)
        {
            setVisible(false)
        }
    };

    const merge = () => {
        setLoading(true)

        api.mergeOrganizations(props.mergeFromOrganization.organizationId,
                                                   props.mergeToOrganization.organizationId)
            .then(success => {
                setLoading(false)
                setVisible(false)
            })
    };
    
    return (
        <Modal size='tiny' open={visible}
               trigger={<Button size='large' fluid color='blue' content='Merge'
                                onClick={open} disabled={props.disabled} />}>
            <Modal.Header content='Confirm Merge Organization' />
            <Modal.Content>
                This action cannot be undone. All organizational data from one organization will be moved into to the other.
                
                {!props.mergeFromOrganization || !props.mergeToOrganization ? null :
                    <Segment textAlign={"center"}> 
                        <Header content={props.mergeFromOrganization.name} subheader={"Created " + props.mergeFromOrganization.createdAt} />
                        <Icon size={"large"} name={"arrow down"} /><Icon size={"large"} name={"arrow down"} />
                        <Header content={props.mergeToOrganization.name} subheader={"Created " + props.mergeToOrganization.createdAt} />
                    </Segment>
                }

                {/*<Modal.Description>*/}
                {/*    <Header content={this.props.user.name} />*/}
                {/*</Modal.Description>*/}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Cancel'/>
                <Button disabled={loading}
                        loading={loading} color='red'
                        onClick={merge} content='Merge' />
            </Modal.Actions>
        </Modal>
    )
}
