import {useContext, useEffect, useState} from "react";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
import {ManagementConsoleAnalyticsResponse} from "../../Models/API Responses/ManagementConsole/Analytics";
import {Grid} from "semantic-ui-react";
import {OrganizationsCard} from "./OrganizationsCard";
import {UsersCard} from "./UsersCard";
import {CrystalControlWebAnalyticsResponse} from "../../Models/API Responses/CrystalControlWeb/CCWAnalytics";
import {CrystalControlWebStatsCard} from "./CrystalControlWebStatsCard";

export function Dashboard() {
    const STCAppsAPI = useContext(StcApplicationsAPIContext)
    //I am sure there are better ways to do this, but this is quick and does the trick.
    const [managementStats, setManagementStats] = useState<ManagementConsoleAnalyticsResponse>({
        orgs: [        {
            "key": "Month"
        },
            {
                "key": "Year",
                "value": 5
            },
            {
                "key": "Total",
                "value": 9
            }],
        users: {
            "admins": 48,
            "users": 52
        }
    })
    const [ccwStats,setCCwStats] = useState<CrystalControlWebAnalyticsResponse>({ gauges: 4, logs: 7})
    useEffect(() => {
        STCAppsAPI.managementConsoleStatistics().then(result => {
            setManagementStats(result)
        })
        STCAppsAPI.crystalControlWebAnalytics().then(result => {
            setCCwStats(result)
        })
    }, [STCAppsAPI])
    return (
        <Grid columns={"equal"}>
            <Grid.Row stretched>
                <Grid.Column>
                    <OrganizationsCard orgs={managementStats.orgs} />
                </Grid.Column>
                <Grid.Column>
                    <UsersCard users={managementStats.users} />
                    <CrystalControlWebStatsCard ccwStats={ccwStats} />
                </Grid.Column>
            </Grid.Row>
            
            
        </Grid>
    )
}