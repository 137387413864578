import { useEffect, useState} from 'react';
import {Image, Menu, Container, Dropdown, Icon} from 'semantic-ui-react';
import { AppVersion } from "../utils/AppVersion";
import {Images} from '@ametektci/ametek.stcappscommon';
import ADAuthStore from '@ametektci/ametek.adauth';

export default function AppHeader() {
    const [userName, setUserName] = useState('')
    useEffect(() => {
        ADAuthStore.getAccountUserName().then(userName => setUserName(userName))
    },[])


        return (
            <Menu fixed='top' inverted size='large'>
                <Container>
                    <Menu.Item href='/'>
                        <Image src={Images.stcLogo} size='small' className='headerImage'/>
                        STC Applications Customer Service Portal
                    </Menu.Item>

                    <Menu.Menu position='right'>

                        <Dropdown item text={userName}>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled>
                                    v. {AppVersion}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={ADAuthStore.logout}>
                                    <Icon name='sign-out' color='red'/>
                                    Sign Out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Menu.Menu>
                </Container>
            </Menu>
        )
    
}
