import {useContext, useState} from 'react';
import {Button, Header, Modal, SemanticSIZES} from 'semantic-ui-react';
import {User} from "../../Models/User";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface SetUserAdminProps
{
    user: User
    size?: SemanticSIZES
}
export default function SetUserAdmin(props: SetUserAdminProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => {
        setVisible(true)
    };

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const setAdmin = () => {
        setLoading(true)

        api.setUserAdmin(props.user.email, !props.user.isAdmin).then((success) => {
            setLoading(false)
            setVisible(!success)
        });
    };
        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button color='blue' size={props.size || 'tiny'} content={props.user.isAdmin ? 'Set As Standard' : 'Set As Admin'}
                                    onClick={open}/>} >
                <Modal.Header content={props.user.isAdmin ? 'Set As Standard' : 'Set As Admin'} />
                <Modal.Content>
                    <Modal.Description>
                        <Header content={props.user.name} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content='Cancel'/>
                    <Button disabled={loading} loading={loading} color='blue'
                            onClick={setAdmin} content={props.user.isAdmin ? 'Set As Standard' : 'Set As Admin'} />
                </Modal.Actions>
            </Modal>
        )
}
