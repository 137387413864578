import {PlanTier} from "./PlanTier";
//WARNING: Space is used as a special character for these strings.
//This is because it is used to assemble a bluesnap plan with the structure of 
//"<Application> <Tier>"
export var ApplicationsWithPlans = [
    //"CrystalControlWeb",
    "Datalogging", //Not really an app, but allows us to manage datalogging prices through Bluesnap
] as const

export type ApplicationWithPlansName = typeof ApplicationsWithPlans[number]
export interface ApplicationPlan {
    newApplication: boolean
    application: ApplicationWithPlansName
    tiers: Array<PlanTier>
}