import {createContext, PropsWithChildren, useContext, useState} from "react";
import {Organization} from "../Models/Organization";
import {StcApplicationsAPIContext} from "./StcApplicationsAPI";
const defaultOrg : Organization = {
    createdAt: new Date(),
    name: "NOT YET LOADED",
    organizationId: 0,
    organizationType: "",
    seats: [],
    trials: [],
    users: []

}
export const OrganizationsContext = createContext({
    getOrg: (id: number) => defaultOrg, //Used when 
    forceReloadOrg: async (id: number) => {}, //Used when we want to indicate that we need more up-to-date information about an organization
})
//The purpose of this context and wrapper is simple.
//Some of the functionality on our site will require knowing the name of an organization.
//Rather than looking it up each time, we can cache organizations for later use.
export function OrganizationsContextWrapper(props: PropsWithChildren)
{
    const api = useContext(StcApplicationsAPIContext)

    const [orgs, setOrgs] = useState<{[key: number] : Organization}>({})
    const loadOrg = async (id: number) => {
        await navigator.locks.request(`LOAD ORG ${id}`, async (lock) => {
            let org = await api.loadOrganization(id)
            setOrgs((organizations) => ({...organizations, [id]:org }))
        })
    }
    const getOrg = (id: number) => {
        if (orgs[id] != undefined)
            return orgs[id]
        loadOrg(id)
        return defaultOrg
    }
    
    return (
        <OrganizationsContext.Provider value={{
            getOrg,
            forceReloadOrg: loadOrg
        } }>{props.children}</OrganizationsContext.Provider>
    )
}