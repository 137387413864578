import {useContext, useState} from 'react';
import {Button, Divider, Dropdown, Header, Modal} from 'semantic-ui-react';
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";

const organizationTypeOptions = [
    {
        key : 'customer',
        text : 'Customer Organization',
        value : 'customer',
    },
    {
        key : 'distributor',
        text : 'Distributor Organization',
        value : 'distributor',
    }
]

interface ChangeOrganizationTypeModalProps {
    org: Organization
    updateOrgType: (type: string) => void
}
export default function ChangeOrganizationTypeModal(props: ChangeOrganizationTypeModalProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('')

    const open = () => {
        setVisible(true)
        setType(props.org.organizationType || 'customer')
    }

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };
    const changeType = () =>{
        setLoading(true)
        api.changeOrganizationType(props.org.organizationId,type.replace('customer', ''))
            .then(success => {
                if (success) {
                    props.updateOrgType(type.replace('customer', ''))
                }
                setLoading(false)
                setVisible(!success)
        })
    }
        // console.log(this.state.type.replace('customer', ''))
    // }

    // cancelSubscription = () => this.setState({loading : true}, () => stcApplicationsStore
    //     .cancelSubscription(this.props.organizationId, this.props.subscriptionId)
    //     .then(success => this.setState({loading : false, visible : !success},
    //         this.props.onCancel(this.props.subscriptionId, success))))
    
    return (
        <Modal open={visible} size='tiny'
               trigger={ <Button basic fluid color='blue' content='Change Type' onClick={open} /> }>
            <Modal.Header>Change Organization Type</Modal.Header>
            <Modal.Content>
                <Header content='Customer Organization' dividing/>
                Customer organizations can access their own organizational data for customer applications.

                <Header content='Distributor Organization' dividing/>
                Distributor organizations can access all customer applications as well as distributor applications such as distributors.stcapplications.com.

                <Divider />

                <Dropdown selection fluid options={organizationTypeOptions} value={type}
                          name='type' onChange={(e, {value}) => setType(value as string)} />

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Close' />
                <Button onClick={changeType} disabled={loading || (type === (props.org.organizationType || 'customer'))}
                        color='blue' content='Change Organization Type' loading={loading}  />
            </Modal.Actions>
        </Modal>
    )
}