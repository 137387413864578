import {Grid, Header, Segment} from "semantic-ui-react";
import RemoveUser from "./RemoveUser";
import TransferUser from "./TransferUser";
import SetUserAdmin from "./SetUserAdmin";
import {User} from "../../Models/User";

interface ExistingUserControlsProps {
    user: User
}

export function ExistingUserControls(props: ExistingUserControlsProps) {

    return (
        <Segment>
            <Header dividing content='Controls' color='grey' size='small'/>
            <Grid columns={4}>
                <Grid.Column><RemoveUser user={props.user} size='large'/></Grid.Column>
                <Grid.Column><TransferUser user={props.user} size='large'/></Grid.Column>
                <Grid.Column><SetUserAdmin user={props.user} size='large'/></Grid.Column>
            </Grid>
        </Segment>
    )
}