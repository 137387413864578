import {Card, Header} from "semantic-ui-react";
import {CrystalControlWebAnalyticsResponse} from "../../Models/API Responses/CrystalControlWeb/CCWAnalytics";

export interface CrystalControlWebStatsCardProps {
    ccwStats: CrystalControlWebAnalyticsResponse
}

export function CrystalControlWebStatsCard(props: CrystalControlWebStatsCardProps) {
    return (
        <Card fluid>
            <Card.Header>
                <Header textAlign={"center"}>Crystal Control Web Usage</Header>
            </Card.Header>
            <Card.Content>
                <ul>
                    <li>
                        Gauges connected: {props.ccwStats.gauges}
                    </li>
                    <li>
                        Logs uploaded: {props.ccwStats.logs}
                    </li>
                </ul>
            </Card.Content>
        </Card>
    )
}