import {
    Button,
    Card,
    CardGroup,
    Dropdown,
    Grid,
    GridColumn,
    GridRow,
    Header, Icon,
    Input,
    Message,
    Segment
} from "semantic-ui-react";
import {gaugeSelection, SubscriptionWrapper} from "./EnterPurchaseOrderDetails";
import {GaugeSelection} from "./GaugeSelection";
import {useEffect} from "react";

export interface PurchaseOrderSubscriptionProps {
    sub: SubscriptionWrapper
    setSub: (sub: SubscriptionWrapper) => void
    removeSub: () => void
    unassigned: Array<gaugeSelection>
    assigned: Array<gaugeSelection>
    addGauge: (sn: string) => void
    removeGauge: (sn: string) => void
    addUnknownGauge: (sn: string) => void
}

const termOptions = [
    {
        key: '1 Year',
        text: '1 Year',
        value: '1 Year',
    },
    {
        key: '3 Years',
        text: '3 Years',
        value: '3 Years',
    },
]

export function PurchaseOrderSubscriptionDetails(props: PurchaseOrderSubscriptionProps) {
    const allSeatsAssigned = props.assigned.length == props.sub.seats
    useEffect(() => {
        props.setSub({...props.sub, ready: allSeatsAssigned})
    }, [allSeatsAssigned])
    const changeNumberOfSeats = (value: string) => {
        let count = parseInt(value)
        if (!Number.isNaN(count) && count > 0) {
            props.setSub({...props.sub, seats: count})
        }
    }
    const changeTerms = (newTerm: string) => {
        props.setSub({...props.sub, validFor: newTerm})
    }


    return (
        <Segment>
            <Grid verticalAlign='middle'>
                <Grid.Column width={6}>
                    <Header size='small' content={props.sub.seatTier}/>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Input label='Seats' labelPosition='right' type='number' value={props.sub.seats} fluid
                           onChange={(e, {value}) => changeNumberOfSeats(value)}
                           min={1}
                    />
                </Grid.Column>

                <Grid.Column width={5}>
                    <Dropdown selection fluid options={termOptions} value={props.sub.validFor}
                              text={'Valid for ' + props.sub.validFor}
                              onChange={(e, {value}) => changeTerms(value as string)}/>
                </Grid.Column>
                <Grid.Column width={2} textAlign='right'>
                    <Button basic color='red' icon='delete' size='mini'
                            onClick={() => props.removeSub()}/>
                </Grid.Column>
            </Grid>
            <CardGroup itemsPerRow={3}>
                {props.assigned.map((a, idx) => <Card>
                    <Card.Header>
                        <Grid>
                            <GridRow columns={"equal"}>
                                <GridColumn textAlign={"center"}>
                                    <Header>
                                        Added Gauge {idx}
                                    </Header>
                                </GridColumn>
                                <GridColumn>
                                    <Button 
                                        icon={<Icon name={"close"} /> } 
                                        fluid 
                                        color={"red"}
                                        onClick={() => props.removeGauge(a.serialNumber)}
                                    ></Button>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Card.Header>
                        <Card.Content>
                            <Input label={"Serial"} fluid labelPosition={"left"} value={a.serialNumber}/>
                            <br />
                            <Input label={"Model"} fluid labelPosition={"left"} value={a.model.name}/>
                        </Card.Content>
                    </Card>
                )
                }

            </CardGroup>
            <GaugeSelection
                existingGaugeSelected={props.addGauge}
                newGaugeSelected={props.addUnknownGauge}
                selectables={props.unassigned}
            />
            {allSeatsAssigned? undefined :
                <Message negative>
                    <Message.Content>
                        Number of purchased seats does not match the number of gauges assigned
                    </Message.Content>
                </Message>
            }
            
        </Segment>
    )
}