import {gaugeSelection} from "./EnterPurchaseOrderDetails";
import {useState} from "react";
import {Button, Dropdown, Grid, Header, Input, Segment} from "semantic-ui-react";

export interface GaugeSelectionProps{
    selectables: Array<gaugeSelection>
    existingGaugeSelected: (sn: string) => void
    newGaugeSelected: (sn: string) => void 
}
export function GaugeSelection(props: GaugeSelectionProps) {
    const [selected, setSelected] = useState("")
    const [newSn, setNewSN] = useState("")
    
    const add = () => {
        if (selected == "")
            return;
        if (selected == "Other")
        {
            if (newSn.length < 5)
                return
            props.newGaugeSelected(newSn)
            setNewSN("")
        }
        else
        {
            props.existingGaugeSelected(selected)
            setSelected("")
        }
    }
    return (
        <Segment>
            <Header>Add Gauge to Purchase Order</Header>
            <Grid relaxed>
                    <Grid.Row>
                        <Grid.Column>
                        <Dropdown
                            placeholder={"Select Gauge"}
                            value={selected}
                            options={props.selectables.map(uag => ({text: uag.serialNumber+" - " + uag.model.name?? "unknown", value: uag.serialNumber, key:uag.serialNumber })).concat([{text:"Other",value: "Other",key: "Other"}])}
                            onChange={(e, {value}) => setSelected(value as string) }
                        />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <Input
                            label={"Serial"}
                            value={selected == "Other"? newSn : selected}
                            onChange={(e, {value}) => setNewSN(value)}
                            disabled={selected != "Other"}
                            error={newSn.length < 6 && selected == "Other"}
                        />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <Button onClick={() => add()}>
                            Add
                        </Button>
                        </Grid.Column>
                    </Grid.Row>
            </Grid>
    </Segment>
    )
}