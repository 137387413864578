import {Grid, Header, Segment} from "semantic-ui-react";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import {User} from "../../Models/User";

interface ExistingUserDetailsProps {
    user: User
    orgName: string
}
export function ExistingUserDetails(props: ExistingUserDetailsProps) {
    return (<Segment>
        <Header dividing content='User Details' color='grey' size='small'/>
        <Grid columns='equal'>
            <Grid.Column>
                <Header content={props.user.email} subheader='Email Address' size='small' block={false}/>
                <Header content={props.orgName} subheader='Organization' size='small' block={false}/>
            </Grid.Column>
            <Grid.Column>
                <Header content={DateUtils.FormatMonthYear(props.user.createdAt)} subheader='User Created On' size='small' block={false}/>
                <Header content={props.user.isAdmin ? 'Admin' : "Standard User"} color={props.user.isAdmin ? 'green' : undefined}
                        subheader='Admin Status' size='small' block={false}/>
            </Grid.Column>
        </Grid>
    </Segment>)
}