import {useContext, useState} from 'react'
import {Header, Button, Modal} from 'semantic-ui-react'
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
import {OrganizationMetadata} from "./OrganizationMetadata";
import {OrganizationUsers} from "./OrganizationUsers";
import {OrganizationTrial} from "./OrganizationTrial";
import {OrganizationSubscriptions} from "./OrganizationSubscriptions";
import {OrganizationGauges} from "./OrganizationGauges";

export default function OrganizationDetails(props: { organizationId: number }) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [org, setOrg] = useState<Organization | null>(null)
    const open = () => {
        if (!org)
            api.loadOrganization(props.organizationId).then((foundOrg) => setOrg(foundOrg))
        setVisible(true)
    }
    const close = () => setVisible(false)

    const onSubscriptionCancel = (subscriptionId: number, success: boolean) => {
        if (!org)
            return
        if (success) {
            let newBillItems = org.billingItems?.map(b => {
                if (b.subscriptionId === subscriptionId)
                    return {...b, status: "CANCELED"}
                return b
            }) ?? []
            setOrg({...org, billingItems: newBillItems})
        }
    }

    const updateOrgType = (orgType: string) => {
        setOrg({...org!, organizationType: orgType})
    }

    return (
        <Modal open={visible} size='fullscreen'
               trigger={<Button content='Open' fluid color={'blue'}
                                onClick={open} size='tiny'/>}
               onClose={close}
               onOpen={open}
        >
            <Modal.Header content='Organization Details'/>
            <Modal.Content>
                {!org
                    ? <Header content='Loading' textAlign='center' disabled/>
                    :
                    <span>
                        <Header content={org.name} size='huge'/>
                        <OrganizationMetadata org={org} updateOrgType={updateOrgType}/>
                        <OrganizationUsers org={org}/>
                        <OrganizationGauges org={org}/>
                        <OrganizationTrial org={org}/>
                        <OrganizationSubscriptions org={org} onSubscriptionCancel={onSubscriptionCancel}/>
                    </span>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button content='Close' onClick={close}/>
            </Modal.Actions>
        </Modal>
    )
}
