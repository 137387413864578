import {useContext, useState} from 'react';
import {Button, Card, Confirm, Divider, Form, Grid, Header, Modal, SemanticSIZES} from 'semantic-ui-react';
import {DateUtils} from '@ametektci/ametek.stcappscommon';
import {User} from "../../Models/User";
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface SetUserAdminProps
{
    user: User
    size?: SemanticSIZES
}
export default function TransferUser (props: SetUserAdminProps){
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [organizationName, setOrganizationName] = useState('')
    const [organizationSearchResults, setOrganizationSearchResults] = useState<Array<Organization>>([])
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>()
    const [confirmVisible, setConfirmVisible] = useState(false)

    const open = () => setVisible(true)
    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const onOrgNameChange = (e: any, { value }: {value: string}) => setOrganizationName(value);
    const showConfirm = (selectedOrganizationId : number) => {
        setConfirmVisible(true)
        setSelectedOrganizationId(selectedOrganizationId)
    };
    const hideConfirm = () => setConfirmVisible(false);

    const searchOrganization = () => {
        if (organizationName.length >= 3) {
            setLoading(true)
            api.searchOrganizations(organizationName).then(organizationSearchResults => {
                setOrganizationSearchResults(organizationSearchResults)
                setLoading(false)
            })
        }
    };

    const transferUser = () => {
        setLoading(true)
        setConfirmVisible(false)

        api.transferUser(props.user.userId, selectedOrganizationId!).then( () => {
            setLoading(false)
            setVisible(false)
        });
    };
    
    return (
        <Modal size='large' open={visible}
               trigger={<Button color='orange' size={props.size || 'tiny'} content='Transfer' onClick={open}/>} >
            <Modal.Header>Transfer User to Organization</Modal.Header>
            <Modal.Content>

                <Form onSubmit={searchOrganization}>
                    <Grid verticalAlign='bottom'>
                        <Grid.Column width={6}>
                            <Header content={props.user.name} subheader={props.user.isAdmin ? 'Administrator' : 'Standard User'}/>
                        </Grid.Column>

                        <Grid.Column width={8}>
                            <Form.Input label='Organization Name' onChange={onOrgNameChange} name='organizationName'
                                        placeholder='Organization Name' />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Button content='Search' color='blue' onClick={searchOrganization}
                                    disabled={loading || organizationName.length < 3} />
                        </Grid.Column>
                    </Grid>
                </Form>

                <Divider />

                <Card.Group itemsPerRow={3}>
                    {organizationSearchResults.map(org => (
                        <Card key={org.organizationId}>
                            <Card.Content header={org.name} />
                            <Card.Content extra content={DateUtils.FormatDate(org.createdAt)} />
                            <Card.Content extra>
                                <Button content='Transfer Here' fluid color='orange' onClick={() => showConfirm(org.organizationId)}/>
                                <Confirm
                                    open={confirmVisible} onCancel={hideConfirm}
                                    onConfirm={transferUser} header='Confirm Transfer User'
                                    content='Are you sure you want to transfer this user? All subscription seats assigned
                                     to this user will be unassigned.'/>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Cancel'/>
            </Modal.Actions>
        </Modal>
    )
}
