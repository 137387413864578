import {useContext, useEffect, useState} from 'react'
import {Button, Grid, Header, Segment, Dropdown, Divider, Form} from 'semantic-ui-react'
import {PurchaseOrderSubscription} from "../../Models/PurchaseOrderSubscription";
import {PurchaseOrderSubscriptionDetails} from "./PurchaseOrderSubscriptionDetails";
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";

const subscriptionOptions = [
    {
        key : 'CrystalControlWeb Standard',
        text : 'CrystalControlWeb Standard',
        value : 'CrystalControlWeb Standard',
    },
    {
        key : 'CrystalControlWeb Advanced',
        text : 'CrystalControlWeb Advanced',
        value : 'CrystalControlWeb Advanced',
    },
];


export interface EnterPurchaseOrderDetailsProps {
    onSubmit: (orderNumber: string, subscriptions: Array<PurchaseOrderSubscription>) => void
    orgInfo: Organization //THIS IS A STUB. It has information like the OrgID, but don't trust for other properties
}
export interface gaugeSelection {serialNumber: string, model: {name?: string}, assignment: number | null}
export interface SubscriptionWrapper extends PurchaseOrderSubscription {
    applicationName: string
    seats: number
    ready: boolean
}
export default function EnterPurchaseOrderDetails(props: EnterPurchaseOrderDetailsProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [orderNumber, setOrderNumber] = useState('')
    const [loadedOrg, setLoadedOrg] = useState<Organization>()
    const [subscriptions, setSubscriptions] = useState<Array<SubscriptionWrapper>>([])
    const [unassignedGauges, setUnassignedGauges] = useState<Array<gaugeSelection>>([])
    /*
    for (let sub of subscriptions) {
                for (let i = 0; i < sub.seats; i++) {
                    convertedSubscriptions.push({
                        ApplicationId : 1,
                        SeatTier : sub.subscription,
                        ValidFor : sub.term
                    })
                }
            }
     */
    useEffect(() => {
        api
            .loadOrganization(props.orgInfo.organizationId)
            .then((org) => {
            setLoadedOrg(org)
        })
    }, [props.orgInfo])
    useEffect(() => {
        if (loadedOrg == undefined || loadedOrg.Gauges == undefined)
        {
            setUnassignedGauges([])
            return
        }
        setUnassignedGauges(
            loadedOrg.Gauges
                .filter(g => !loadedOrg.seats.some(s => s.serialNumber == g.serialNumber))
                .map(g => ({serialNumber: g.serialNumber, model: g.model, assignment: null}))?? []
        )
    }, [loadedOrg])
    const addSubscription = (value: string) => {
        let split = value.split(" ")
        if (subscriptions.some(s => s.seatTier === value))
            return;
        setSubscriptions(subscriptions.concat([
            {
                seatTier : split[1],
                seats : 1,
                validFor : '1 Year',
                applicationName: split[0],
                applicationId: 1,
                gauges: [],
                ready: false,
            }
        ]).sort(s => s.applicationId))
    }
    const updateSubscription = (sub: SubscriptionWrapper, index: number) => {
        setSubscriptions(
            subscriptions.map((s, idx) => {
                if (idx == index)
                    return sub
                return s
            })
        )
        
    }
    const removeSubscription = (index: number) => {
        setSubscriptions(subscriptions.filter((s, idx) =>idx != index))
    }

    const handleOrderNumberChange = (e: any, {value}: {value: string}) => setOrderNumber(value)

    const submit = () => props.onSubmit(orderNumber, subscriptions.map((s, idx) => ({
        seatTier: s.seatTier,
        validFor: s.validFor, 
        applicationId: s.applicationId,
        gauges: unassignedGauges.filter(g => g.assignment == idx).map(g => g.serialNumber)
    })))
    
    const assignGauge = (sn: string, idx: number) => {
        setUnassignedGauges(unassignedGauges.map(g => g.serialNumber == sn? {...g, assignment: idx} : g))
    }
    const unassignGauge = (sn: string) => {
        setUnassignedGauges(unassignedGauges.map(g => g.serialNumber == sn? {...g, assignment: null} : g))
    }
    const addUnknownGauge = (sn: string, idx: number | null) => {
        setUnassignedGauges(unassignedGauges.concat([{
            serialNumber: sn,
            model: {
                name: "unknown"
            },
            assignment: idx
        }]))
    }
        return (
            <Segment>

                <Form>
                    <Form.Input label='Purchase Order Number' onChange={handleOrderNumberChange}
                                value={orderNumber} placeholder='Purchase Order Number' />
                </Form>

                <Divider />

                {subscriptions.length === 0 ? <Header content='No Subscriptions Added' /> : null }

                {subscriptions.map((sub, idx) => (
                    <div key={idx}>
                        {
                            sub.applicationId != subscriptions[idx-1]?.applicationId ? <Header content={sub.applicationName} /> : undefined
                        }
                        <PurchaseOrderSubscriptionDetails 
                            unassigned={unassignedGauges.filter(g => g.assignment == null)}
                            assigned={unassignedGauges.filter(g => g.assignment == idx)}
                            addGauge={(sn) => assignGauge(sn, idx)}
                            removeGauge={(sn) => unassignGauge(sn) }
                            addUnknownGauge={(sn) => addUnknownGauge(sn, idx)}
                            key={idx} 
                            sub={sub} 
                            setSub={(updatedSub) => updateSubscription(updatedSub, idx)} removeSub={() => removeSubscription(idx)}  
                            />
                    </div>
                                    ))}

                <Grid columns={2}>
                    <Grid.Column>
                        <Dropdown text='Add Subscription' icon='plus' floating options={subscriptionOptions}
                                  labeled button className='icon' onChange={(e, {value}) => addSubscription(value as string)} />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Button color='blue' disabled={subscriptions.length === 0 || orderNumber.length < 6 || subscriptions.some(s => !s.ready)}
                                content='Submit' onClick={submit} />
                    </Grid.Column>
                </Grid>

            </Segment>
        )
}
