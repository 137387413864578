import {Header, Segment, Grid, Button} from 'semantic-ui-react'
import {Organization} from "../../Models/Organization";
import {PurchaseOrderSubscription} from "../../Models/PurchaseOrderSubscription";

export interface PurchaseOrderconfirmationProps {
    organization: Organization
    orderNumber: string
    subscriptions: Array<PurchaseOrderSubscription>
    loading: boolean
    onSubmit: () => void
}
export default function PurchaseOrderConfirmation(props: PurchaseOrderconfirmationProps) {
        return (
            <Segment >
                <Header content='Confirm Purchase Order Subscription' color='blue' dividing/>

                <Grid columns={2}>
                    <Grid.Column>
                        <Header content={props.organization.name} subheader='Organization' />
                    </Grid.Column>
                    <Grid.Column>
                        <Header content={props.orderNumber} subheader='Order Number' />
                    </Grid.Column>

                </Grid>

                {props.subscriptions.map(sub => (
                    <Grid as={Segment} key={sub.seatTier} verticalAlign='middle'>
                        <Grid.Column width={6}>
                            <Header size='small' content={sub.seatTier} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header content={`${sub.gauges.length} seats`} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header content={`Valid for ${sub.validFor}`} />
                        </Grid.Column>
                    </Grid>
                ))}

                <Button fluid color='green' content='Submit' onClick={props.onSubmit} loading={props.loading}/>

            </Segment>
        )
}
