import {useContext, useState} from 'react';
import {Button, Modal } from 'semantic-ui-react';
import {TrialPeriod} from "../../Models/TrialPeriod";
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface ExtendTrialPeriodProps {
    trial: TrialPeriod
    organization: Organization
}
export default function ExtendTrialPeriod(props: ExtendTrialPeriodProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => setVisible(true)
    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const extendTrialPeriod = () => {
        api.extendTrial(props.organization.organizationId, props.trial.trialId).then(() => {
            setLoading(false)
            setVisible(false)
        });
    };
    return (
        <Modal size='tiny' open={visible}
               trigger={<Button content='Extend' fluid  size='tiny' onClick={open}/>} >
            <Modal.Header>Extend Trial Period</Modal.Header>
            <Modal.Content>
                <Modal.Description>This will reset this organization's trial period. The trial will expire 14 days from today.</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content='Cancel' />
                <Button positive disabled={loading}
                        loading={loading} onClick={extendTrialPeriod} content='Confirm' />
            </Modal.Actions>
        </Modal>
    )
}
