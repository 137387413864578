import {Button, Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import {AwsUserInfo} from "../../Models/API Responses/userRequest";
import {useContext, useState} from "react";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
interface AwsUserControlsProps {
    info: AwsUserInfo
}

export function AwsUserControls(props: AwsUserControlsProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [confirmationSent, setConfirmationSent] = useState(false)
    const [problem, setProblem] = useState<string>()
    const [busy, setBusy] = useState(false)
    const updatePassword = () => {
        setBusy(true)
        api.changePassword(props.info.username).then((response) => {

            setPasswordChanged(response)
        })
            .catch((reason) => {
                console.log(reason)
                setProblem("Update password failed. Debug information in console")
            })
            .finally(() => {
                setBusy(false)
            })
    }

    const reSendConfirmation = () => {
        setBusy(true)
        api.verifyEmail(props.info.username).then((response) => {
            setConfirmationSent(true)
        })
            .catch((reason) => {
                console.log(reason)
                setProblem("Update password failed. Debug information in console")
            })
            .finally(() => setBusy(false))
    }
    return (
        <Segment>
            <Header dividing content='Fix Login' color='grey' size='small'/>
            <Grid columns={"equal"}>
                <Grid.Row>
                    <Grid.Column>
                        {props.info.emailVerified ? <span style={{color:"green"}} >Email Verified</span> :
                            <p><span style={{color:"red"}}>Email Not Verified</span><br/>User can log in with any password.</p>}
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            icon={confirmationSent ? <Icon name={"check"}/> : <Icon name={"refresh"}/>}
                            content={"Resend Confirmation"}
                            disabled={confirmationSent || props.info.emailVerified}
                            onClick={reSendConfirmation}
                            loading={busy}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            icon={passwordChanged ? <Icon name={"check"}/> : <Icon name={"refresh"}/>}
                            content={"Reset Password"}
                            disabled={passwordChanged || !props.info.emailVerified}
                            onClick={updatePassword}
                            loading={busy}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Message hidden={problem == undefined} content={problem} error/>
        </Segment>
    )
}