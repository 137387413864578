import {useContext, useEffect, useState} from "react";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
import {ApplicationPlan} from "../../Models/ApplicationPlan";
import {
    Accordion,
    AccordionContent,
    AccordionTitle, Form,
    Header,
    Message,
    Segment
} from "semantic-ui-react";
import {PlanTier} from "../../Models/PlanTier";

const defaultDLPlan: ApplicationPlan = {
    application: "Datalogging",
    tiers: [],
    newApplication: false
}

export function ManageOneTimePurchasable() {
    const api = useContext(StcApplicationsAPIContext)
    const [dataLoggingOptions, setDataLoggingOptions] = useState<ApplicationPlan>(defaultDLPlan)
    const [showDataLogging, setShowDataLogging] = useState(true)
    const supportedProducts = ["XP3I"]
    const [newProduct, setNewProduct] = useState("")
    const [showAddNewProduct, setShowAddNewProduct] = useState(false)
    
    useEffect(() => {
        loadPlans()
    }, [])
    const loadPlans = () => {
        api.getAvailablePlans().then(pg => setDataLoggingOptions(pg.filter(p => p.application == "Datalogging")[0] ?? defaultDLPlan))
    }
    const onAddPlan = () => {
        setDataLoggingOptions(dl => ({
            ...dl, tiers: dl.tiers.concat({
                name: newProduct,
                Application: "Datalogging",
                plans: [],
                newGroup: true
            })
        }))
    }
    return (
        <Segment>
            <Header>
                Manage One Time Purchase Prices
            </Header>
            <Accordion as={Segment}>
                <AccordionTitle onClick={() => setShowDataLogging(!showDataLogging)} active={showDataLogging}
                                content={"DataLoggerXP"} as={Header}/>
                <AccordionContent active={showDataLogging}>
                    
                        {dataLoggingOptions.tiers.map(pt =>
                            <DataLoggerPurchaseOption onUpdate={loadPlans} tier={pt} key={pt.name}/>
                        )
                        }
                        <Accordion>
                            <AccordionTitle content={"Add New Product"} active={showAddNewProduct} onClick={() => setShowAddNewProduct(!showAddNewProduct)} />
                            <AccordionContent active={showAddNewProduct}>
                                Only supported products are listed.
                                <Form>
                                    <Form.Group>
                                        <Form.Dropdown
                                            options={supportedProducts.filter(sp => !dataLoggingOptions.tiers.some(dlo => dlo.name === sp)).map(sp => ({
                                                text: sp,
                                                value: sp
                                            }))}
                                            value={newProduct}
                                            onChange={(e, {value}) => setNewProduct(value as string)}
                                            search selection
                                        />
                                        <Form.Button onClick={onAddPlan} disabled={newProduct == ""}>Add Product</Form.Button>
                                    </Form.Group>
                                </Form>
                            </AccordionContent>
                        </Accordion>
                    
                </AccordionContent>
            </Accordion>
        </Segment>
    )
}

interface DataloggerPurchaseOptionProps {
    tier: PlanTier
    onUpdate: () => void
}

function DataLoggerPurchaseOption(props: DataloggerPurchaseOptionProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [productVisible, setProductVisible] = useState(false)
    const plans = props.tier.plans;
    const relevant = plans.filter(p => p.chargeFrequency == "ONCE")[0] ?? {
        newPlan: true,
        status: "ACTIVE",
        chargeFrequency: "ONCE",
        name: props.tier.name,
        gracePeriodDays: 1,
        trialPeriodDays: 1,
        initialChargeAmount: 0,
        planId: 0,
        currency: "USD",
        maxNumberOfCharges: 1,
        recurringChargeAmount: 0,
        chargeOnPlanSwitch: true,
    }
    const [price, setPrice] = useState(relevant.recurringChargeAmount.toString())
    useEffect(() => {
        setPrice(relevant.recurringChargeAmount.toString())
    }, [relevant.recurringChargeAmount])
    const asNumber = Number.parseFloat(price)
    console.log(asNumber)
    const updatePrice = () => {
        if (isNaN(asNumber))
            return
        let remove = plans.map(p => api.updatePlan({...p, status: "INACTIVE"}))
        let add = api.updatePlan({
            newPlan: true,
            status: "ACTIVE",
            chargeFrequency: "ONCE",
            name: "Datalogging " + props.tier.name,
            gracePeriodDays: 1,
            trialPeriodDays: 1,
            initialChargeAmount: 0,
            planId: 0,
            currency: "USD",
            maxNumberOfCharges: 1,
            recurringChargeAmount: asNumber,
            chargeOnPlanSwitch: true,
        })
        Promise.all(remove.concat([add])).then(props.onUpdate)
    }
    return (
        <Accordion>
            <AccordionTitle onClick={() => setProductVisible(!productVisible)} content={"Product: "+props.tier.name} active={productVisible} />
            <AccordionContent active={productVisible}>
                {plans.length > 1 ? <Message info>
                    <Message.Header>
                        Too many prices defined
                    </Message.Header>
                    <Message.Content>
                        <p>You are likely seeing this message because someone used a different tool to set the price of
                            a
                            DataLoggerXP Purchase.</p>
                        <p>This issue may cause the wrong price to show up in Crystal Control Web or be charged to the
                            customer.</p>
                        <p>Enter the correct price to correct this issue.</p>
                    </Message.Content>
                </Message> : null}
                <Form>
                    Price - USD
                    <Form.Group widths={"equal"}>
                        <Form.Input value={price} type={"number"}
                                    onChange={(e, {value}) => setPrice(value)}
                                    error={isNaN(asNumber) ? "Price must be a number" : undefined}
                                    step={.01}
                        />

                        <Form.Button
                            onClick={updatePrice}
                            disabled={(price == relevant.recurringChargeAmount.toString()) && plans.length == 1 || isNaN(asNumber)}
                            inline
                        >
                            Update Price
                        </Form.Button>
                    </Form.Group>
                </Form>
            </AccordionContent>
        </Accordion>
    )
}