import {createRoot} from 'react-dom/client';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import {SetLanguage, SetEnvironment} from "@ametektci/ametek.stcappscommon";
import {ExportEnvironmentSettings} from "./EnvironmentSettings";
import {AppWithRoutes} from "./AppWithRoutes";

SetLanguage('english')
SetEnvironment(ExportEnvironmentSettings());
const root = createRoot(document.getElementById('root')!)
root.render(<AppWithRoutes />)
