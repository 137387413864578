import {Organization} from "../../Models/Organization";
import {Card, Grid, Header, Segment} from "semantic-ui-react";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import {FormatOrganizationType} from "../../utils/AppUtils";
import ChangeOrganizationTypeModal from "./ChangeOrganizationTypeModal";

export function OrganizationMetadata(props: OrganizationMetadataProps)
{
    return (
        <Segment>
            <Grid columns='equal'>
                <Grid.Column>
                    <Header content={DateUtils.FormatDate(props.org.createdAt)} subheader='Created On' size='small' block={false}/>
                </Grid.Column>
                <Grid.Column>
                    <Card>
                        <Card.Content header={FormatOrganizationType(props.org.organizationType)} />
                        <Card.Content extra>
                            <ChangeOrganizationTypeModal org={props.org} updateOrgType={props.updateOrgType}/>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}
export interface OrganizationMetadataProps
{
    updateOrgType: (type: string) => void,
    org: Organization
}