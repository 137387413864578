import {Button, Card, Header} from "semantic-ui-react";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import {Gauge} from "@ametektci/ametek.stcappscommon/src/DataStructures/Gauge";
import {useContext} from "react";
import {OrganizationsContext} from "../contexts/OrganizationsContext";

export interface DeviceInfoCardProps {
    device: Gauge
    onSelected: () => void
    selected: boolean
}

export function DeviceInfoCard(props: DeviceInfoCardProps) {
    const orgs = useContext(OrganizationsContext)
    const org = orgs.getOrg(props.device.organizationId)
    return (
        <Card>
            <Card.Content header={props.device.name}/>
            <Card.Content extra>
                <Header content={org.name} subheader={"Organization"} size={"small"} sub={true} />
                <Header content={props.device.serialNumber} subheader={"Serial Number"} size={"small"} sub={true}/>
                <Header content={props.device.model.name} subheader={"Model"} size={"small"} sub={true}/>
                <Header content={`${DateUtils.FormatDate(props.device.lastSeen)}`} subheader='Last Connected'
                        size='small' sub={true}/>
            </Card.Content>
            <Card.Content extra>
                <Button content='Load' fluid color='blue' onClick={props.onSelected} disabled={props.selected}/>
            </Card.Content>
        </Card>
    )
}