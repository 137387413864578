import {createContext, PropsWithChildren, useEffect, useState} from "react";
import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {authenticationStore} from "@ametektci/ametek.stcappscommon/src";
import {ResetPasswordRequest, ResetPasswordResponse} from "../Models/API Responses/ResetPasswordRequest";
import {
    ManagementConsoleAnalyticsRequest,
    ManagementConsoleAnalyticsResponse
} from "../Models/API Responses/ManagementConsole/Analytics";
import {EnvironmentResponse} from "../Models/API Responses/EnvironmentRequest";
import {CrystalControlWebAnalyticsResponse} from "../Models/API Responses/CrystalControlWeb/CCWAnalytics";
import {GenerateUnlockCodeRequest, GenerateUnlockCodeResponse} from "../Models/API Responses/GenerateUnlockCodeRequest";
import {OrganizationSearchRequest, OrganizationSearchResponse} from "../Models/API Responses/OrganizationSearchRequest";
import {messageQueue} from "@ametektci/ametek.stcappscommon";
import {Organization} from "../Models/Organization";
import {OrganizationRequest, organizationResponse} from "../Models/API Responses/OrganizationRequest";
import {userResult} from "../Models/API Responses/userRequest";
import stcApplicationsStore from "../stores/StcApplicationsStore";
import {PurchaseOrderSubscription} from "../Models/PurchaseOrderSubscription";
import {PurchaseOrderResponse, PurchasesOrderRequest} from "../Models/API Responses/PurchasesOrderRequest";
import {Gauge} from "@ametektci/ametek.stcappscommon/src/DataStructures/Gauge";
import {GetPlansRequest, GetPlansResponse} from "../Models/API Responses/GetPlansRequest";
import {BlueSnapPlan} from "../Models/BlueSnapPlans";
import {UpdatePlansRequest, UpdatePlansResponse} from "../Models/API Responses/UpdatePlansRequest";
import {ApplicationPlan, ApplicationsWithPlans, ApplicationWithPlansName} from "../Models/ApplicationPlan";
import {ActivationRecord} from "../Models/ActivationRecord";

export interface StcApplicationsAPIInterface {
    changePassword: (username: string) => Promise<boolean>
    verifyEmail: (username: string) => Promise<void>
    managementConsoleStatistics: () => Promise<ManagementConsoleAnalyticsResponse>
    crystalControlWebAnalytics: () => Promise<CrystalControlWebAnalyticsResponse>
    generateUnlockCode: (serial: string, model: string) => Promise<GenerateUnlockCodeResponse>
    searchOrganizations: (orgName: string) => Promise<Array<Organization>>
    loadOrganization: (organizationId: number) => Promise<Organization>
    searchUsers: (searchField: string, searchTerm: string) => Promise<userResult[]>
    transferUser: (userId: number, orgId: number) => Promise<void>
    removeUser: (userEmail: string) => Promise<boolean>
    setUserAdmin: (userEmail : string, isAdmin : boolean) => Promise<boolean>
    extendTrial: (organizationId: number, trialId: number) => Promise<boolean>
    addPurchaseOrder: (organizationId: number, orderNumber: string, subscriptions : Array<PurchaseOrderSubscription> ) => Promise<boolean>
    cancelSubscription: (organizationId : number, subscriptionId: number) => Promise<boolean>
    mergeOrganizations: (mergeFromOrganizationId : number, mergeIntoOrganizationId: number) => Promise<boolean>
    changeOrganizationType: (organizationId : number, organizationType: string) => Promise<boolean>
    searchDevices: (serialNumber: string) => Promise<Array<Gauge>>
    enableDataLogger: (gaugeId: number) => Promise<boolean>
    enableDataLoggerXP: (serial: string, series: string) => Promise<boolean>
    revokeActivationRecord: (record: number, reason: string) => Promise<void>,
    getAvailablePlans: () => Promise<ApplicationPlan[]>
    updatePlan: (plans: BlueSnapPlan) => Promise<BlueSnapPlan>
    getUnrevokedDLActivations: (serialNumber: string) => Promise<Array<string>>
    getDlActivationRecords: (serialNumber: string) => Promise<Array<ActivationRecord>>
}

export const StcApplicationsAPIContext = createContext<StcApplicationsAPIInterface>({
    changePassword: async (username: string) => {
        return new Promise(resolve => false)
    },
    verifyEmail: async (username: string) => Promise.reject("Not Set up"),
    managementConsoleStatistics: async () => {
        return new Promise(resolve => {
            return {
                orgs: [{key: "Total", value:0}],
                users: {users: 0, admins: 0}
            }
        })
    },
    crystalControlWebAnalytics: async () => {
        return new Promise(resolve => {
            return {
                logs: 7,
                gauges: 4
            }
        })
    },
    generateUnlockCode: async () => {
        return new Promise(resolve => {
            return "NO BACKEND"
        })
    },
    searchOrganizations: async (orgName: string) => {return new Promise( resolve => {return []})},
    loadOrganization: async (organizationId: number) => (new Promise(resolve => ({organizationId: 0,
        name: "NO CONNECTION",
        createdAt: new Date(),
        organizationType: "",
        users: [],
        trials: [],
        Gauges: [],
        billingItems: [],
        seats: []}))),
    searchUsers: async () => (new Promise(resolve => ([]))),
    transferUser: async () => (new Promise(resolve => {})),
    removeUser: async () => (new Promise(resolve => false)),
    setUserAdmin: async () => (new Promise(resolve => false)),
    extendTrial: async () => (new Promise(resolve => false)),
    addPurchaseOrder: async () => (new Promise<boolean>(resolve => false)),
    cancelSubscription: async () => (new Promise(resolve => false)),
    mergeOrganizations: async () => (new Promise(resolve => false)),
    changeOrganizationType: async () => (new Promise(resolve => false)),
    searchDevices: async () => (new Promise(resolve => ([]))),
    enableDataLogger: async () => (new Promise(resolve => false)),
    enableDataLoggerXP: async () => (new Promise(resolve => false)),
    revokeActivationRecord: async () => Promise.resolve(),
    getAvailablePlans: async () => (new Promise(resolve => ([]))),
    updatePlan: async (plan) => (new Promise(resolve => plan)),
    getUnrevokedDLActivations: async () => Promise.resolve([]),
    getDlActivationRecords: async () => Promise.resolve([])
})
//Communicates with CustomerService API. This context should become the ONLY thing that uses our internal networking.
//When this is the only component that can access the network, we will be able to test all components
//using controlled data.
export function StcApplicationsAPIContextWrapper(props: PropsWithChildren) {
    const generateAuthHeader = async (request: InternalAxiosRequestConfig) => {
        request.headers.Authorization = "bearer " + await authenticationStore.getAccessToken()
        return request
    }
    const [ManagementConsoleAPI, setManagementConsoleAPI] = useState("")
    const ManagementAxios = axios.create({
        baseURL: ManagementConsoleAPI
    })
    ManagementAxios.interceptors.request.use(generateAuthHeader)
    const [crystalControlWebAPI, setCrystalControlWebAPI] = useState("")
    const CCWAxios = axios.create({
        baseURL: crystalControlWebAPI
    })
    CCWAxios.interceptors.request.use(generateAuthHeader)
    const csAxios = axios.create({})
    csAxios.interceptors.request.use(generateAuthHeader)
    
    useEffect(() => {
        csAxios.get<EnvironmentResponse>("/Environment").then(response => {
            setManagementConsoleAPI(response.data.managementConsoleAPI)
            setCrystalControlWebAPI(response.data.crystalControlWebApi)
        })
    },[])
    const changePassword = async (username: string) => {
        try {
            let result = await csAxios.post<ResetPasswordResponse, AxiosResponse<ResetPasswordResponse>, ResetPasswordRequest>("/ResetUserPassword", {
                username: username
            })
            return result.data?.success ?? false
        } catch (e) {
            reportError(e)
            return false
        }
    }
    const verifyEmail = async (username: string) => {
        await csAxios.post("/User/ReSendVerification", {username: username})
    }
    const crystalControlWebStatistics = async () : Promise<CrystalControlWebAnalyticsResponse> => {
        let result = {
            logs: 7,
            gauges: 4
        };
        if (crystalControlWebAPI != "")
        {
            let response = await CCWAxios.post("/CustomerService/Analytics", {
                jwtRequestToken: await authenticationStore.getAccessToken(),
            })
            if (response.status == 200)
                result = response.data
        }
        return result
    }
    const managementConsoleStatistics = async () : Promise<ManagementConsoleAnalyticsResponse> => {
        let result : ManagementConsoleAnalyticsResponse = {
            orgs: [], 
            users: {admins: 0, users: 0},
        }
        if (ManagementConsoleAPI != "")
        {
            let response = await ManagementAxios.post<ManagementConsoleAnalyticsResponse, AxiosResponse<ManagementConsoleAnalyticsResponse>, ManagementConsoleAnalyticsRequest>(ManagementConsoleAPI + "/CustomerService/Analytics", {
                jwtRequestToken: await authenticationStore.getAccessToken(),
            })
            result = response.data
        }
        return result
    }
    //You're gonna look at the code in the back end and think "We could do that on the client!"
    //DON'T.
    //I put it on the back end specifically so that it can't be sent to users outside Ametek.
    const generateUnlockCode = async (serialNumber: string, model: string) : Promise<GenerateUnlockCodeResponse> => {
        let result = await csAxios.post<GenerateUnlockCodeResponse, AxiosResponse<GenerateUnlockCodeResponse>, GenerateUnlockCodeRequest>("/GaugeUnlockCodes",{
            serialNumber: serialNumber,
            model: model
        }, {validateStatus:() => true})
        if (result.status == 200)
            return result.data
        console.log(result)
        return { passwordReset: "ERROR", factoryAccess: "ERROR"}
    }
    const searchOrganizations = async (organizationName : string) => {
        try {
            let response = await csAxios.post<OrganizationSearchResponse, AxiosResponse<OrganizationSearchResponse>, OrganizationSearchRequest>("/SearchOrganization", {
                OrganizationName : organizationName
            });

            return response.data.results.map(res => {
                res.organization.organizationType = res.organizationType;
                return res.organization;
            });
        }
        catch (error) {
            messageQueue.sendError(`Error occurred while searching organizations`)
            return [];
        }
    }
    const getOrgGauges = async (orgId: number): Promise<Array<Gauge>> => {
        let response = await CCWAxios.post("/CustomerService/GaugesForOrg", {OrgId: orgId})
        return response.data.gauges;
    } 
    const loadOrganization = async (organizationId: number) : Promise<Organization> => {
        try {
            let response = await csAxios.post<organizationResponse,  AxiosResponse<organizationResponse>, OrganizationRequest>("/LoadOrganization", {
                organizationId : organizationId
            });
            if (response.data.organization == undefined)
            {
                messageQueue.sendError("Unable to load organization - Org does not appear to exist.")
                //@ts-ignore
                return {}
            }
            let organization = response.data.organization;
            organization.billingItems = response.data.billingItems;
            organization.organizationType = response.data.organizationType;
            organization.Gauges = await getOrgGauges(organizationId)
            return organization;
        }
        catch (error) {
            messageQueue.sendError(`Error occurred while loading organizations`)
            console.log(error)
            //@ts-ignore
            return {};
        }
    }
    const searchUsers = async (searchFieldName : string, searchTerm: string) => {
        try {
            let response = await csAxios.post<Array<userResult>>("/SearchUser", {
                SearchField : searchFieldName,
                SearchTerm : searchTerm
            });

            return response.data ?? [];
        }
        catch (error) {
            messageQueue.sendError(`Error occurred while searching users`)
            return [];
        }
    }
    const transferUser = async (userId : number, organizationId : number) => {
        csAxios.post("/TransferUser", {
            UserId : userId,
            OrganizationId : organizationId
        }).then((response) => {
        }).catch ((error) => {
            console.log('error transferring user', error);
            messageQueue.sendError(`Error occurred while Transferring User`)
        })
    }
    const removeUser = async (userEmail: string) => {
        return await csAxios.post("/RemoveUser", {
            UserEmail : userEmail
        }).then((response) => {
            if (!response.data.success)
                messageQueue.sendError(response.data.errorMessage, "Error while Removing User")
            stcApplicationsStore.emit('setUserAdminStatusEvent', response.data.success);
            return response.data.success
        }).catch ((error) => {
            console.log('Error while Removing User', error);
            messageQueue.sendError(`Error while Removing User`);
            stcApplicationsStore.emit('setUserAdminStatusEvent', false);
            return false
        })
    }
    const setUserAdmin = async (userEmail : string, isAdmin : boolean) => {
        return await csAxios.post("/SetUserAdmin", {
            UserEmail : userEmail,
            IsAdmin : isAdmin
        }).then((response) => {
            if (!response.data.success)
                messageQueue.sendError(response.data.errorMessage, "Error while Setting User Admin Status")
            stcApplicationsStore.emit('setUserAdminStatusEvent', response.data.success);
            return response.data.success
        }).catch ((error) => {
            console.log('Error while Setting User Admin Status', error);
            messageQueue.sendError(`Error while Setting User Admin Status`)
            stcApplicationsStore.emit('setUserAdminStatusEvent', false);
            return false
        })
    }
    const extendTrial = async (organizationId: number, trialId: number) => {
        return await csAxios.post("/ExtendTrial", {
            OrganizationId : organizationId,
            TrialId : trialId
        }).then((response) => {
            return true
        }).catch ((error) => {
            console.log('error extending trial period', error);
            messageQueue.sendError(`Error Occurred while Extending Trial Period`)
            return false
        })
    }
    const addPurchaseOrder = async (organizationId: number, orderNumber : string, subscriptions : Array<PurchaseOrderSubscription>) => {
        try {
            let response = await csAxios.post<PurchaseOrderResponse, AxiosResponse<PurchaseOrderResponse>, PurchasesOrderRequest>("/PurchaseOrder", {
                organizationId : organizationId,
                orderNumber : orderNumber,
                subscriptions : subscriptions
            })

            return response.data.success;
        }
        catch (error) {
            console.error(error);
            messageQueue.sendError(`Error Occurred while adding Purchase Order`);
            return false;
        }
    }
    const cancelSubscription = async (organizationId : number, subscriptionId: number) => {
        try {
            let response = await csAxios.post("/CancelSubscription", {
                OrganizationId : organizationId,
                SubscriptionId : subscriptionId
            });

            if(!response.data.success)
                messageQueue.sendError('Error occurred while cancelling subscription', response.data.errorMessage)

            return response.data.success;
        }
        catch (error) {
            console.log(error)
            messageQueue.sendError('Error occurred while cancelling subscription')
            return false;
        }
    }

    const mergeOrganizations = async (mergeFromOrganizationId : number, mergeIntoOrganizationId: number) => {
        try {
            let response = await csAxios.post('/MergeOrganizations', {
                MergeFromOrganizationId : mergeFromOrganizationId,
                MergeIntoOrganizationId : mergeIntoOrganizationId
            });

            if (response.data.success) {
                messageQueue.sendSuccess('Organizations Merged')
                stcApplicationsStore.emit('organizationsMergedEvent')
            }

            else
                messageQueue.sendError('Error occurred while merging organizations', response.data.errorMessage)

            return response.data.success;
        }
        catch (error) {
            console.error(error)
            messageQueue.sendError('Error occurred while merging organizations')
            return false;
        }
    }

    const changeOrganizationType = async (organizationId : number, organizationType: string) => {
        try {
            let response = await csAxios.post('/SetOrganizationType', {
                OrganizationId : organizationId,
                OrganizationType : organizationType
            });

            if (response.data.success) {
                messageQueue.sendSuccess('Organization Type Changed')
            }

            else
                messageQueue.sendError('Error occurred while changing organization type', response.data.errorMessage)

            return response.data.success;
        }
        catch (error) {
            console.error(error)
            messageQueue.sendError('Error occurred while changing organization type')
            return false;
        }
    }
    const searchDevices = async (serialNumber: string) => {
        try {
            let response = await CCWAxios.post<{devices: Array<Gauge>}>("/CustomerService/DeviceSearch", {
                SerialNumber : serialNumber
            })

            return response.data.devices || [];
        }
        catch (error) {
            messageQueue.sendError(`Error Occurred while Searching Devices`)
            return [];
        }
    }
    const enableDataLoggerXP = async (serialNumber: string, series: string) => {
        let response = await CCWAxios.post("/CustomerService/EnableDataLoggerXP", {
            serialNumber: serialNumber,
            series: series
        }, {validateStatus: () => true})
        return response.status == 204 || response.status == 200
    }
    const enableDataLogger = async (gaugeId : number) => {
        try {
            let response = await CCWAxios.post("/CustomerService/EnableDataLogger", {
                GaugeId : gaugeId
            })
            //@ts-ignore
            return response.success;
        }
        catch (error) {
            messageQueue.sendError(`Error Occurred while Enabling Data Logger`)
            return false;
        }
    }
    const revokeActivationRecord = async (activationRecord: number, reason: string) =>
    {
        await CCWAxios.post("/CustomerService/Revoke", {ActivationId: activationRecord, reason:reason})
    }
    const getDLPurchases = async (serialNumber: string) => {
        return (await CCWAxios.post("/CustomerService/Devices/Purchases", {serialNumber: serialNumber})).data
    }
    const getDlActivationRecords = async (serialNumber: string) => {
        return (await CCWAxios.post("/CustomerService/Activations", {serialNumber: serialNumber})).data as Array<ActivationRecord>
    }
    const getAvailablePlans = async () =>
    {
        let response = await csAxios.get<GetPlansResponse, AxiosResponse<GetPlansResponse>, GetPlansRequest>("/Plans")
        return wrapPlans(response.data.plans)
    }
    const updatePlan = async (plans: BlueSnapPlan)=>
    {
        let response = await csAxios.post<UpdatePlansResponse, AxiosResponse<UpdatePlansResponse>, UpdatePlansRequest>("/Plans/Update", {
            newPlan: plans
        })
        return response.data.updatedPlan
    }
    const wrapPlans = (plans: Array<BlueSnapPlan>) =>
    {
        var applicationPlans : Array<ApplicationPlan>= []
        for (let plan of plans)
        {
            let name = plan.name.split(" ")
            //Only work with plans for known applications. Leave the rest alone.
            if (name.length > 1 && ApplicationsWithPlans.includes(name[0] as ApplicationWithPlansName)) {
                if (!applicationPlans.some(ap => ap.application == name[0])) {
                    applicationPlans.push({
                        application: name[0] as ApplicationWithPlansName,
                        newApplication: false,
                        tiers: []
                    })
                }
                // We created if it doesn't exist in the previous step, so it's fine to assume it exists.
                let targetApp = applicationPlans.find(ap => ap.application == name[0])!
                if (!targetApp.tiers.some(tier => tier.name == name[1])) {
                    targetApp.tiers.push({
                        name: name[1],
                        plans: [],
                        Application: name[0] as ApplicationWithPlansName,
                        newGroup: false
                    })
                }
                //We just created it, so it should exist.
                targetApp.tiers.find(tier => tier.name == name[1])!.plans.push({...plan, newPlan: !(plan.planId > 0)})
            }
        }
        return applicationPlans
    }
    return (
        <StcApplicationsAPIContext.Provider value={{
            verifyEmail,
            changePassword,
            managementConsoleStatistics,
            crystalControlWebAnalytics: crystalControlWebStatistics,
            generateUnlockCode: generateUnlockCode,
            searchOrganizations,
            loadOrganization,
            searchUsers,
            transferUser,
            removeUser,
            setUserAdmin,
            extendTrial,
            changeOrganizationType,
            addPurchaseOrder,
            cancelSubscription,
            mergeOrganizations,
            searchDevices,
            enableDataLogger,
            enableDataLoggerXP,
            revokeActivationRecord,
            getAvailablePlans,
            updatePlan,
            getUnrevokedDLActivations: getDLPurchases,
            getDlActivationRecords,
        }}>
            {props.children}
        </StcApplicationsAPIContext.Provider>
    )
}
