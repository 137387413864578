import {useContext, useEffect, useState} from 'react'
import {Button, Card, Form, Grid, Header, Loader, Segment} from 'semantic-ui-react'
import stcApplicationsStore from "../../stores/StcApplicationsStore";
import { validate}  from "email-validator";
import {userResult} from "../../Models/API Responses/userRequest";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";
import {UserCard} from "./UserCard";
export default function UserLookup() {
    const api = useContext(StcApplicationsAPIContext)
    const [loading, setLoading] = useState(false)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userNotFound, setUserNotFound] = useState(false)
    const [searchResults, setSearchResults] = useState<userResult[]>([])
    useEffect(() => {
        stcApplicationsStore.on('removeUserFinishedEvent', searchUsers)
        stcApplicationsStore.on('setUserAdminStatusEvent', searchUsers)
        return () => {
            stcApplicationsStore.off('removeUserFinishedEvent', searchUsers)
            stcApplicationsStore.off('setUserAdminStatusEvent', searchUsers) 
        }
    })
    const searchUsers = () => {
        setLoading(true)
        setUserNotFound(false)

        if (validate(userEmail))
            api.searchUsers('email', userEmail).then(onUserSearchLoad);
        else if (userName.length > 3)
            api.searchUsers('name', userName).then(onUserSearchLoad);
    };

    const onUserSearchLoad = (searchResults: userResult[]) => {
        setSearchResults(searchResults)
        setLoading(false)
        setUserNotFound(searchResults.length === 0)
    }


    let result = null;

    if (loading)
        result = <Loader active inline='centered' />;
    else if (userNotFound)
        result = <Header size='medium' textAlign='center' disabled content='No Results'/>;
    else {
        result = <Card.Group itemsPerRow={3}>
            {searchResults.map(res => (
                <UserCard user={res} showOrg={true}/>
            ))}
        </Card.Group>
    }

    return (
        <Segment >
            <Header content='User Lookup' size='huge'/>

            <Segment >
                <Header dividing content='Search' color='blue' />

                <Form onSubmit={searchUsers}>

                    <Grid verticalAlign='bottom'>
                        <Grid.Column width={13}>
                            <Form.Input label='Email Address' onChange={(e, {value}) => setUserEmail(value) } name='userEmail'
                                        placeholder='user@company.com' value={userEmail}/>

                            <Form.Input label='Name' onChange={(e, {value}) => setUserName(value)} name='userName'
                                        placeholder='Full Name' value={userName} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button content='Search' color='blue' fluid
                                    disabled={loading || !(validate(userEmail) || userName.length > 3)} />
                        </Grid.Column>
                    </Grid>
                </Form>

            </Segment>

            {result}

        </Segment>
    )
}
