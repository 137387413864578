import {useState} from 'react'
import {Header, Button, Modal,} from 'semantic-ui-react'
import {ExistingUserDetails} from "./ExistingUserDetails";
import {CurrentOrganizationInformation} from "./CurrentOrgInformation";
import {ExistingUserControls} from "./ExistingUserControls";
import {AwsUserControls} from "./AwsUserControls";
import {userResult} from "../../Models/API Responses/userRequest";

export interface UserDetailsProps {
    user: userResult
    hideOpenOrg?: boolean
}

export default function UserDetails(props: UserDetailsProps) {
    const [visible, setVisible] = useState(false)

    const open = () => setVisible(true)
    const close = () => setVisible(false)

    return (
        <Modal open={visible}
               trigger={<Button content='Open' fluid color='blue' size='tiny'
                                onClick={open}/>}>
            <Modal.Header content='User Details'/>
            <Modal.Content>
                {props.user.user != null ? 
                    <>
                        <Header content={props.user.user.name} size='huge'/>
                        <ExistingUserDetails user={props.user.user} orgName={props.user.organizationName}/>
                        <CurrentOrganizationInformation orgId={props.user.user.organizationId} hideOpenOrg={props.hideOpenOrg} orgName={props.user.organizationName}/>
                        <ExistingUserControls user={props.user.user}/>
                    </>: null }
                {props.user.awsUserInfo != null ? <>
                    <AwsUserControls info={props.user.awsUserInfo}/>
                </> : null }
                
            
            </Modal.Content>
            <Modal.Actions>
                <Button content='Close' onClick={close}/>
            </Modal.Actions>
        </Modal>
    )
}
