import {useContext, useState} from "react";
import {Button, Modal } from 'semantic-ui-react';
import {Gauge} from "@ametektci/ametek.stcappscommon/src/DataStructures/Gauge";
import {StcApplicationsAPIContext} from "../contexts/StcApplicationsAPI";

interface EnableDataLogProps {
    device: Gauge
    onDataLogEnabled: (success: boolean) => void
}
export default function EnableDataLog(props: EnableDataLogProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => {
        setVisible(true)
    }
    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const enableDataLogging = () => {
        setLoading(true)

        api.enableDataLogger(props.device.gaugeId).then(success => {
            setLoading(false)
            setVisible(false)
            props.onDataLogEnabled(success)
        })
    };
    
        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button content='Enable DataLoggerXP' fluid  size='large' color='blue' onClick={open} disabled={props.device.dataLogEnabled || props.device.dataLogPurchased}/>} >
                <Modal.Header>Enable Data Log on this Device</Modal.Header>
                <Modal.Content>
                    <Modal.Description>Are you sure you want to enable Data Logging on this {props.device.model.shortName}?</Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content='Cancel' />
                    <Button positive disabled={loading}
                            loading={loading} onClick={enableDataLogging} content='Enable' />
                </Modal.Actions>
            </Modal>
        )
}
