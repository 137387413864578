import {useContext, useState} from "react";
import {Button, Card, Form, Grid, Header, Loader, Segment} from 'semantic-ui-react'
import {DateUtils} from '@ametektci/ametek.stcappscommon';
import OrganizationDetails from "./OrganizationDetails";
import {FormatOrganizationType, OrganizationTypeColor} from "../../utils/AppUtils";
import {Organization} from "../../Models/Organization";
import {StcApplicationsAPIContext} from "../../contexts/StcApplicationsAPI";

export interface organizationSearchProps {
    onSelected?: (org: Organization) => void
}
export default function OrganizationSearch(props: organizationSearchProps) {
    const api = useContext(StcApplicationsAPIContext)
    const [organizationSearchResults, setOrganizationSearchResults] = useState<Array<Organization>>([])
    const [loading, setLoading] = useState(false)
    const [organizationName, setOrganizationName] = useState('')
    const [noResults, setNoResults] = useState(false)

    const searchOrganization = () => {
        if (organizationName.length >= 3) {
            setLoading(true)
            setNoResults(false)

            api.searchOrganizations(organizationName).then((organizationSearchResults) => {
                    setOrganizationSearchResults(organizationSearchResults)
                    setLoading(false)
                    setNoResults(organizationSearchResults.length === 0)
                }
            )
        }
    };

    let results = null;

    if (loading)
        results = <Loader active inline='centered'/>;
    else if (noResults)
        results = <Header size='medium' textAlign='center' disabled content='No Organizations Found'/>;
    else {
        results = <Card.Group itemsPerRow={3}>
            {organizationSearchResults.map(org => (
                <Card key={org.organizationId}>
                    <Card.Content header={org.name}/>
                    <Card.Content extra content={'Created At: ' + DateUtils.FormatDate(org.createdAt)}/>
                    <Card.Content extra>
                        <Header size='small' content={FormatOrganizationType(org.organizationType)}
                                color={OrganizationTypeColor(org.organizationType)}/>
                    </Card.Content>
                    <Card.Content extra>
                        <OrganizationDetails organizationId={org.organizationId}/>
                        <br />
                        {props.onSelected != undefined ? <Button content='Load' fluid color='blue' onClick={() => props.onSelected!(org)}/> : undefined }
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    }

    return (
        <Segment>
            <Header content='Organization Lookup' size='huge'/>

            <Segment>
                <Header dividing content='Search' color='blue'/>

                <Form onSubmit={searchOrganization}>
                    <Grid verticalAlign='middle'>
                        <Grid.Column width={13}>
                            <Form.Input label='Organization Name' onChange={(e, {value}) => setOrganizationName(value)}
                                        name='organizationName'
                                        placeholder='Organization Name'/>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button content='Search' color='blue' fluid
                                    disabled={loading || organizationName.length < 3}/>
                        </Grid.Column>
                    </Grid>
                </Form>

            </Segment>

            {results}

        </Segment>
    )
}